.partners {
  display: grid;
  gap: 24px;
  padding-bottom: 60px;

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__case {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &__img {
    width: 154px;
    height: 46px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .partners {
    gap: 64px;
    padding-bottom: 140px;

    &__text {
      font-size: 48px;
    }

    &__case {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
    }

    &__img {
      width: 220px;
      height: 64px;
    }
  }
}

@media (min-width: 1440px) {
  .partners {
    gap: 64px;
    padding-bottom: 140px;
    width: 1312px;
    margin: 0 auto;

    &__text {
      font-size: 48px;
    }

    &__case {
      display: flex;
      width: 1312px;
      margin: 0 auto;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }

    &__img {
      width: 220px;
      height: 64px;
    }
  }
}
