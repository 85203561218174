.architecture {
  display: flex;
  width: 328px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 233px;
  padding: 16px 0px;
  margin-bottom: 60px;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: var(--Background-bg-accent, $color-container);

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    text-align: center;
    font-family: Nunito Sans;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.335px;
  }

  &__img {
    width: 328px;
    height: 196px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .architecture {
    width: 692px;
    height: 492px;
    padding: 33.785px 0px;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 32px;

    &__text {
      font-size: 27px;
      letter-spacing: 0.708px;
    }

    &__img {
      width: 692px;
      height: 414px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .architecture {
    display: flex;
    width: 1117px;
    height: 924px;
    padding: 64px;
    gap: 64px;
    border-radius: 32px;
    margin-top: 140px;
    margin-bottom: 140px;

    &__text {
      font-size: 48px;
      letter-spacing: 1.341px;
    }

    &__img {
      width: 1117px;
      height: 667px;
    }
  }
}

@media (min-width: 1440px) {
  .architecture {
    display: flex;
    width: 1312px;
    height: 924px;
    padding: 64px;
    gap: 64px;
    border-radius: 32px;
    margin-top: 140px;
    margin-bottom: 140px;

    &__text {
      font-size: 48px;
      letter-spacing: 1.341px;
    }

    &__img {
      width: 1117px;
      height: 667px;
    }
  }
}
