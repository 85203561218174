.machine {
  padding-bottom: 60px;

  &__case {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 60px;
  }

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: "Nunito Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__wraper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    align-self: stretch;
  }

  &__line {
    display: flex;
    padding-bottom: 60px;
    border-bottom: 1px solid var(--Border-color-border-default, #d8d8d8);
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;

    &_big {
      font-weight: 700;
    }
  }

  &__img {
    width: 328px;
    height: 139px;
    border-radius: 3.466px;
  }

  &__imgs {
    width: 328px;
    height: 164px;
  }

  &__button {
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: var(--Button-color-button-default, $color-orange);
    transition: all 0.3s ease;

    &:hover {
      background: #fcbe56;
    }
  }

  &__span {
    color: var(--Text-color-text-white, $color-white);
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Background-bg-element, $color-pale-orange);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;

    &__case {
      padding-bottom: 80px;
      align-items: center;
    }

    &__title {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, #3d3d40)
      );
      font-family: "Nunito Sans";
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.341px;
    }

    &__wraper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      align-self: stretch;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
    }

    &__container {
      flex-direction: row;
    }

    &__line {
      display: none;
    }

    &__text {
      width: 334px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 140px;

    &__case {
      display: flex;
      align-items: center;
      gap: 64px;
      padding-bottom: 140px;
    }

    &__title {
      font-size: 48px;
    }

    &__wraper {
      gap: 140px;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex-direction: row;
      gap: initial;
      // width: 1312px;
    }

    &__line {
      display: none;
    }

    &__text {
      font-size: 16px;
      // width: 324px;
    }

    &__img {
      width: 757px;
      height: 321px;
    }

    &__imgs {
      width: 757px;
      height: 378px;
    }

    &__button {
      width: 400px;
    }

    &__span {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 140px;

    &__case {
      display: flex;
      align-items: center;
      gap: 64px;
      padding-bottom: 140px;
    }

    &__title {
      font-size: 48px;
    }

    &__wraper {
      gap: 140px;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex-direction: row;
      gap: initial;
      width: 1312px;
    }

    &__line {
      display: none;
    }

    &__text {
      font-size: 16px;
      width: 424px;
    }

    &__img {
      width: 757px;
      height: 321px;
    }

    &__imgs {
      width: 757px;
      height: 378px;
    }

    &__button {
      width: 400px;
    }

    &__span {
      font-size: 16px;
    }
  }
}
