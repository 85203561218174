.projects {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 60px;

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__container {
    display: flex;
    padding: 16px;
    text-decoration: none;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;
    align-self: stretch;
    border-radius: 24px;
    background: var(--Background-bg-accent, $color-container);
    transition: all 0.3s ease;

    &:hover {
      background: $color-white;

      .projects__mount {
        background: #3cb4f6;
      }

      .projects__imgs path {
        fill: $color-white;
      }
    }
  }

  &__case {
    display: flex;
    justify-content: space-between;
  }

  &__crisper {
    display: grid;
    gap: 8px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 127px;
  }

  &__depiction {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__img {
    height: 135px;
    aspect-ratio: 1/1;
  }

  &__up {
    width: 100px;
    height: 135px;
  }

  &__liver {
    width: 100px;
    height: 135px;
  }

  &__we {
    width: 100px;
    height: 135px;
  }

  &__flame {
    width: 100px;
    height: 135px;
  }

  &__casket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 24px;
  }

  &__strip {
    width: 100%;
    border-top: 3px solid $color-orange;
  }

  &__mount {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Icon-color-icon-normal, $color-white);
    transition: all 0.3s ease;
  }

  &__imgs {
    width: 32px;
    height: 33px;
  }

  &__figure {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .projects {
    padding-bottom: 80px;

    &__wrap {
      align-content: flex-start;
      gap: 32px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
    }

    &__container {
      height: 259px;
      text-decoration: none;
      flex-direction: column;
      justify-content: space-between;
      gap: initial;
    }
    &__case {
      display: flex;
    }

    &__crisper {
      display: grid;
      gap: 8px;
      width: 196px;
    }

    &__text {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, #3d3d40)
      );
      font-family: Nunito Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 89px;
    }

    &__depiction {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, #3d3d40)
      );
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.44px;
    }

    &__casket {
      align-self: initial;
    }

    &__strip {
      width: 198px;
    }

    &__circle {
      padding: 12px 24px;
    }

    &__imgs {
      width: 32px;
      height: 33px;
    }

    &__figure {
      width: 330px;
      height: 259px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .projects {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-bottom: 140px;

    &__title {
      font-size: 48px;
    }

    &__wrap {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }

    &__container {
      display: flex;
      width: 424px;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
    }

    &__case {
      display: flex;
    }

    &__crisper {
      display: grid;
      gap: 12px;
      width: 238px;
    }

    &__text {
      font-size: 32px;
      width: 277px;
    }

    &__depiction {
      font-size: 16px;
      width: 238px;
    }

    &__img {
      height: 194px;
      aspect-ratio: 1/1;
    }

    &__up {
      width: 145px;
      height: 194px;
    }

    &__liver {
      width: 145px;
      height: 194px;
    }

    &__we {
      width: 145px;
      height: 194px;
    }

    &__flame {
      width: 145px;
      height: 194px;
    }

    &__casket {
      width: 376px;
    }

    &__strip {
      width: 248px;
    }

    &__mount {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Icon-color-icon-normal, $color-white);
    }

    &__imgs {
      width: 32px;
      height: 33px;
    }

    &__figure {
      width: 421px;
      height: 323px;
    }
  }
}

@media (min-width: 1440px) {
  .projects {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 1312px;
    margin: 0 auto;
    padding-bottom: 140px;

    &__title {
      font-size: 48px;
    }

    &__wrap {
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      flex-wrap: wrap;
      flex-direction: row;
    }

    &__container {
      display: flex;
      width: 424px;
      padding: 24px;
      flex-direction: column;
      justify-content: space-between;
    }

    &__case {
      display: flex;
    }

    &__crisper {
      display: grid;
      gap: 12px;
      width: 238px;
    }

    &__text {
      font-size: 32px;
      width: 277px;
    }

    &__depiction {
      font-size: 16px;
      width: 238px;
    }

    &__img {
      height: 194px;
      aspect-ratio: 1/1;
    }

    &__up {
      width: 145px;
      height: 194px;
    }

    &__liver {
      width: 145px;
      height: 194px;
    }

    &__we {
      width: 145px;
      height: 194px;
    }

    &__flame {
      width: 145px;
      height: 194px;
    }

    &__casket {
      width: 376px;
    }

    &__strip {
      width: 248px;
    }

    &__mount {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Icon-color-icon-normal, $color-white);
    }

    &__imgs {
      width: 32px;
      height: 33px;
    }

    &__figure {
      width: 424px;
      height: 323px;
    }
  }
}
