.leaving {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;

  .side-button-2 {
    padding-left: 16px;
  }

  .side-panel-bg {
    width: 100vw;
    height: 100vh;
    background: #000000a0;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -120vw;
    align-items: center;
  }

  .side-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1169px;
    color: #fff;
    padding: 32px 38px;
    background: var(--Border-color-border-accent, $color-blue);
    box-shadow: 0px 4px 12px 0px rgba(18, 24, 58, 0.16);
  }

  .side-button-1 {
    cursor: pointer;
  }

  #side-checkbox {
    display: none;
  }

  .side-case {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-top: 30px;
  }

  .side-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
  }

  .side-text {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;

    &.active {
      color: var(--Text-color-text-white, $color-pale-grey);
    }
  }

  #side-checkbox:checked + .side-panel-bg {
    left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .leaving {
    display: flex;
    padding: 30px 38px;

    .side-panel-bg {
      align-items: initial;
    }

    .side-panel {
      // height: 100;
      // width: 100vw;
      height: 438px;
      width: 360px;
      padding-bottom: 163px;
      flex-direction: column;
      border-radius: 0px 0px 64px 0px;
    }

    .side-button-1 {
      cursor: pointer;
    }

    #side-checkbox {
      display: none;
    }

    .side-case {
      display: flex;
      flex-direction: column;
      gap: 64px;
      padding-top: 30px;
      height: 438px;
      width: 360px;
    }

    .side-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 44px;
    }

    .side-text {
      color: var(--Text-color-text-white, $color-white);
      font-family: Nunito Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
    }
  }
}

@media (min-width: 1200px) {
  .leaving {
    display: none;
  }
}
