.portfolio-header {
  display: flex;
  padding: 60px 16px 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  &__button {
    display: flex;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--Background-bg-primary, $color-orange);
  }

  &__title {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__text {
    color: var(--Text-color-text-white, $color-white);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }

  &__card {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 24px;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
  }

  &__exile {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
    transition: all 0.5s ease;

    &:hover {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-blue)
      );
    }
  }

  &__img {
    width: 328px;
    height: 290px;
    border-radius: 24px;

    &_active {
      width: 200px;
      height: 200px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .portfolio-header {
    padding: 30px 38px 80px 38px;
    align-items: flex-end;
    gap: 24px;
    flex-direction: row;

    &_wrap {
      justify-content: space-around;
      align-self: stretch;
      margin: initial;
      flex-direction: row;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    &__text {
      text-align: left;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .portfolio-header {
    display: flex;
    padding: initial;
    flex-direction: row;
    gap: 100px;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
    }

    &_wrap {
      justify-content: space-around;
      align-self: stretch;
      padding: initial;
      margin: initial;
      flex-direction: row;
      gap: 100px;
    }

    &__button {
      width: initial;
    }

    &__title {
      font-size: 41px;
    }

    &__text {
      font-size: 32px;
      width: initial;
      text-align: left;
    }

    &__card {
      padding: 16px 32px;
      width: initial;
    }

    &__exile {
      font-size: 16px;
    }

    &__img {
      width: 500px;
      height: 440px;
      &_active {
        width: 240px;
        height: 234px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .portfolio-header {
    display: flex;
    padding: initial;
    margin: 0 auto;
    box-sizing: border-box;
    flex-direction: row;
    gap: 100px;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
    }

    &_wrap {
      justify-content: space-around;
      align-self: stretch;
      padding: initial;
      margin: initial;
      flex-direction: row;
      gap: 100px;
    }

    &__button {
      display: inline-block;
    }

    &__title {
      font-size: 46px;
    }

    &__text {
      font-size: 40px;
      width: 712px;
      text-align: left;
    }

    &__card {
      padding: 16px 32px;
      width: 464px;
    }

    &__exile {
      font-size: 16px;
    }

    &__img {
      width: 500px;
      height: 440px;
      &_active {
        width: 240px;
        height: 234px;
      }
    }
  }
}
