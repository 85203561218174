.answer {
  display: flex;
  padding: 30px 16px 60px 16px;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  margin-bottom: 60px;
  background: var(
    --Background-bg-accent-gradient,
    linear-gradient(270deg, #56c2fe 0%, #99e2de 100%)
  );

  &__wrap {
    display: grid;
    gap: 16px;
  }

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__crisper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .answer__container {
    width: 100%;
    transition: height 0.5s ease;

    &:nth-child(1) {
      .answer__panel_show {
        height: 362px;
        .answer__text {
          height: 321px;
        }
      }
    }
    &:nth-child(2) {
      .answer__panel_show {
        height: 295px;
        .answer__text {
          height: 240px;
        }
      }
    }
    &:nth-child(3) {
      .answer__panel_show {
        height: 143px;
        .answer__text {
          height: 94px;
        }
      }
    }
    &:nth-child(4) {
      .answer__panel_show {
        height: 179px;
        .answer__text {
          height: 137px;
        }
      }
    }
  }

  .accordion {
    display: flex;
    padding: 16px 32px 0px 32px;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
  }

  .accordion:after {
    content: "\002B";
    transition: transform 0.5s ease;
    font-size: 31px;
    color: #3d3d40;
  }

  .active:after {
    transform: rotate(45deg);
  }

  &__span {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    width: 200px;
    letter-spacing: 0.44px;
  }

  &__panel {
    padding-left: 32px;
    padding-right: 32px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    width: calc(100% - 64px);
    overflow: hidden;
    height: 16px;
    transition: height 0.5s ease;
    .answer__text {
      height: 0;

      transition: height 0.5s ease;
      display: flex;
      overflow: hidden;
      padding-bottom: 0px;
      margin-top: 12px;

      &_bonds {
        display: block;
      }
    }
    &_show {
      transform: translateY(0);
      .answer__text {
        padding-bottom: 16px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
    padding-bottom: 16px;
    transition: height 0.5s ease;
    p {
      transition: height 0.5s ease;
    }

    &_bonds {
      display: block;
    }
  }

  &__ref {
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-blue)
      );
    }
  }

  &__link {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
    transition: all 0.5s ease;

    &:hover {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-blue)
      );
    }
  }

  &__button {
    display: flex;
    height: 56px;
    padding: 12px 24px;
    cursor: pointer;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: var(--Button-color-button-default, $color-orange);
    transition: height 0.3s ease;
    width: 194px;

    &:hover {
      background: #fcbe56;
    }
  }

  &__word {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__arrow {
    display: flex;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Background-bg-element, $color-pale-orange);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .answer {
    width: 641px;
    margin-left: initial;
    margin-right: initial;
    border-radius: 0px 32px 32px 0px;

    &__wrap {
      display: grid;
      gap: 16px;
    }

    &__text {
      width: 513px;
    }

    .answer__container {
      width: 100%;
      transition: height 0.5s ease;

      &:nth-child(1) {
        .answer__panel_show {
          height: 160px;
          .answer__text {
            height: 130px;
          }
        }
      }
      &:nth-child(2) {
        .answer__panel_show {
          height: 150px;
          .answer__text {
            height: 120px;
          }
        }
      }
      &:nth-child(3) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
      &:nth-child(4) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
    }

    &__panel {
      padding-left: 32px;
      padding-right: 32px;
      background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
      overflow: hidden;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .answer {
    padding: 32px 64px 64px 64px;
    width: 66%;
    margin-left: initial;
    margin-right: 64px;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;

    &__title {
      font-size: 48px;
    }

    .answer__container {
      &:nth-child(1) {
        .answer__panel_show {
          height: 160px;
          .answer__text {
            height: 130px;
          }
        }
      }
      &:nth-child(2) {
        .answer__panel_show {
          height: 150px;
          .answer__text {
            height: 120px;
          }
        }
      }
      &:nth-child(3) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
      &:nth-child(4) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
    }

    .accordion:after {
      content: "\002B";
      font-size: 31px;
      color: #3d3d40;
    }

    &__span {
      width: 419px;
      font-size: 16px;
    }

    &__panel {
      padding-left: 32px;
      padding-right: 32px;

      background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
      height: 16px;
      overflow: hidden;
    }

    &__text {
      width: 750px;
      font-size: 16px;
    }

    &__link {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) and (max-width: 1719px) {
  .answer {
    padding: 32px 64px 64px 204px;
    width: 1043px;
    margin-left: initial;
    margin-right: 64px;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;

    &__title {
      font-size: 48px;
    }

    &__wrap {
      display: grid;
      gap: 16px;
    }

    .answer__container {
      width: 100%;
      transition: height 0.5s ease;

      &:nth-child(1) {
        .answer__panel_show {
          height: 160px;
          .answer__text {
            height: 130px;
          }
        }
      }
      &:nth-child(2) {
        .answer__panel_show {
          height: 150px;
          .answer__text {
            height: 120px;
          }
        }
      }
      &:nth-child(3) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
      &:nth-child(4) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
    }

    .accordion:after {
      content: "\002B";
      font-size: 31px;
      color: #3d3d40;
    }

    &__span {
      width: 419px;
      font-size: 16px;
    }

    &__panel {
      padding-left: 32px;
      padding-right: 32px;
      background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
      overflow: hidden;
    }

    &__text {
      width: 750px;
      font-size: 16px;
    }

    &__link {
      font-size: 16px;
    }
  }
}

@media (min-width: 1720px) {
  .answer {
    padding: 32px 64px 64px 20%;
    padding-left: 64px;
    width: auto;
    margin-left: initial;
    margin-right: 50%;
    //padding-left: 38%;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;
    justify-content: end;
    flex-direction: initial;

    &__wrap {
      //display: flex;
      //justify-content: stretch;
      //width: 100%;
      gap: 16px;
      flex-direction: column;
    }

    &__title {
      font-size: 48px;
    }

    .answer__container {
      width: 100%;
      transition: height 0.5s ease;

      &:nth-child(1) {
        .answer__panel_show {
          height: 160px;
          .answer__text {
            height: 130px;
          }
        }
      }
      &:nth-child(2) {
        .answer__panel_show {
          height: 150px;
          .answer__text {
            height: 120px;
          }
        }
      }
      &:nth-child(3) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
      &:nth-child(4) {
        .answer__panel_show {
          height: 108px;
          .answer__text {
            height: 70px;
          }
        }
      }
    }

    .accordion:after {
      content: "\002B";
      font-size: 31px;
      color: #3d3d40;
    }

    &__span {
      width: 419px;
      font-size: 16px;
    }

    &__panel {
      padding-left: 32px;
      padding-right: 32px;
      background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
      overflow: hidden;
    }

    &__text {
      width: 750px;
      font-size: 16px;
    }

    &__button {
      width: 198px;
    }

    &__link {
      font-size: 16px;
    }
  }
}
