.depiction-wrap {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 76px;

  .depiction {
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 24px;
    background: var(--Background-bg-light, #fff);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);

    &__title {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-pale-grey)
      );
      font-family: Nunito Sans;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.341px;
    }

    &__container {
      display: flex;
      padding: 16px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      align-self: stretch;
    }

    &__case {
      display: grid;
      gap: 16px;
    }

    &__text {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-pale-grey)
      );
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.44px;
    }

    &__img {
      width: 200px;
      height: 200px;
    }

    &__button {
      display: flex;
      height: 56px;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      text-decoration: none;
      padding: 12px 24px;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
      background: var(
        --Button-color-button-secondary,
        linear-gradient(270deg, $color-blue 0%, $color-turquoise 100%)
      );
      transition: all 0.3s ease;

      &:hover {
        background: #3cb4f6;
      }
    }

    &__span {
      color: var(--Text-color-text-white, $color-white);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.44px;
    }

    &__arrow {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 5.333px 6.054px 5.334px 5.189px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Icon-color-icon-normal, $color-white);
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .depiction-wrap {
    padding-left: 127px;
    padding-right: initial;

    .depiction {
      display: flex;
      padding: 38px;
      border-radius: 32px 0px 0px 32px;

      &__container {
        justify-content: center;
        gap: 32px;
        align-self: stretch;
        flex-direction: row;
      }

      &__case {
        display: grid;
        gap: 16px;
      }

      &__text {
        width: 100%;
      }

      &__img {
        width: 150px;
        height: 150px;
      }

      &__button {
        gap: 12px;
        width: 246px;
        background: var(
          --Button-color-button-secondary,
          linear-gradient(270deg, #3ab2f5 0%, #92ebe6 100%)
        );
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .depiction-wrap {
    display: flex;
    justify-content: flex-end;
    background: initial;
    padding-left: initial;
    padding-right: initial;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 140px;
    z-index: 99;

    .depiction {
      padding: 32px 64px;
      border-radius: 32px 0px 0px 32px;

      &__title {
        font-size: 48px;
      }

      &__container {
        flex-direction: row;
      }

      &__case {
        display: grid;
        gap: 16px;
      }

      &__text {
        font-size: 16px;
        width: 683px;
      }

      &__button {
        width: 264px;
        gap: 12px;
      }

      &__span {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 1440px) and (max-width: 1719px) {
  .depiction-wrap {
    display: flex;
    justify-content: flex-end;
    background: initial;
    padding-left: initial;
    padding-right: initial;
    width: 100%;
    padding-top: 260px;
    padding-bottom: 140px;
    z-index: 99;

    .depiction {
      padding: 32px 64px;
      border-radius: 32px 0px 0px 32px;

      &__title {
        font-size: 48px;
      }

      &__container {
        flex-direction: row;
      }

      &__case {
        display: grid;
        gap: 16px;
      }

      &__text {
        font-size: 16px;
        width: 683px;
      }

      &__button {
        width: 264px;
        gap: 12px;
      }

      &__span {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 1720px) {
  .depiction-wrap {
    display: flex;
    justify-content: end;
    background: initial;
    padding-left: 43%;
    padding-right: initial;
    width: auto;
    padding-top: 260px;
    padding-bottom: 140px;
    z-index: 99;

    .depiction {
      padding: 32px 20% 32px 64px;
      border-radius: 32px 0px 0px 32px;
      justify-content: stretch;
      width: 100%;

      &__title {
        font-size: 48px;
      }

      &__container {
        flex-direction: row;
        justify-content: flex-start;
      }

      &__case {
        display: grid;
        gap: 16px;
      }

      &__text {
        font-size: 16px;
        width: 683px;
      }

      &__button {
        width: 264px;
        gap: 12px;
      }

      &__span {
        font-size: 16px;
      }
    }
  }
}
