.header {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #3cb4f6, #3cb4f6 41%, white 40%);

  &__container {
    display: grid;
    gap: 60px;
  }

  &__wrap {
    display: flex;
    gap: 16px;
    padding-top: 57px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__img {
    width: 62px;
    height: 68px;
  }

  &__case {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__imgs {
    width: 250px;
    padding-top: 16.424px;
  }

  &__text {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.097px;
  }

  &__depiction {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header {
    border-radius: 0px 0px 0px 64px;
    background: linear-gradient(180deg, #3cb4f6, #3cb4f6 61%, white 61%);

    &__container {
      display: grid;
      gap: 60px;
    }

    &__wrap {
      gap: 32px;
      padding-top: 57px;
      padding-left: 36px;
      padding-right: 90px;
    }

    &__img {
      width: 120px;
      height: 133px;
    }

    &__case {
      gap: 16px;
    }

    &__imgs {
      width: 487px;
      padding-top: 32px;
    }

    &__depiction {
      padding-left: 127px;
      padding-right: initial;
    }
  }
}

@media (min-width: 1200px) {
  .header {
    display: none;
  }
}
