.provisions {
  padding: 60px 16px 60px 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__title {
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    color: #000;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;

    &_big {
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .provisions {
    padding: 80px 38px 80px 38px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .provisions {
    padding: 140px 64px 140px 64px;

    &__title {
      font-size: 32px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .provisions {
    padding: 140px 64px 140px 64px;
    margin: 0 auto;
    width: 1312px;
    box-sizing: border-box;

    &__title {
      font-size: 32px;
    }

    &__text {
      font-size: 16px;
    }
  }
}
