.intrusion {
  display: flex;
  padding: 30px 16px;
  margin-bottom: 60px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Background-bg-accent, $color-container);

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__card {
    display: flex;
    padding: 32px 16px;
    align-items: center;
    box-sizing: border-box;
    gap: 16px;
    border-radius: 24px;
    border: 2px solid var(--Border-color-border-accent, $color-blue);
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
  }

  &__number {
    color: var(--Text-color-text-special, $color-blue);
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__case {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;

    &_min {
      font-size: 14px;
      font-weight: 400;
    }

    &_beam {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__button {
    display: flex;
    padding: 12px 24px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    align-self: stretch;
    border-radius: 16px;
    background: var(--Button-color-button-default, $color-orange);
    transition: all 0.3s ease;

    &:hover {
      background: #fcbe56;
    }
  }

  &__span {
    color: var(--Text-color-text-white, $color-white);
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Background-bg-element, $color-pale-orange);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .intrusion {
    margin-bottom: 80px;

    &__title {
      text-align: center;
      width: 642px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    &__card {
      padding: 16px;
    }

    &__number {
      color: var(--Text-color-text-special, $color-blue);
      font-family: Nunito Sans;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.341px;
    }

    &__case {
      padding: 0px 16px;
    }

    &__text {
      width: 135px;

      &_beam {
        width: 182px;
      }
    }

    &__button {
      width: 182px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .intrusion {
    display: flex;
    padding: 64px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 140px;
    gap: 64px;
    border-radius: 32px;

    &__title {
      text-align: center;
      font-size: 48px;
    }

    &__container {
      gap: 20px;
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    &__card {
      display: flex;
      height: 152px;
      width: 300px;
      padding: 16px 32px;
      border-radius: 32px;
    }

    &__number {
      font-size: 44px;
    }

    &__case {
      padding: 0px 32px;
      gap: 8px;
    }

    &__text {
      font-size: 13px;

      &_min {
        font-size: 13px;
      }

      &_beam {
        font-size: 16px;
        width: 237px;
      }
    }

    &__button {
      display: flex;
      padding: 12px 24px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-radius: 16px;
      background: var(--Button-color-button-default, $color-orange);
    }

    &__span {
      font-size: 16px;
    }

    &__arrow {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 5.333px 6.054px 5.334px 5.189px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Background-bg-element, $color-pale-orange);
    }
  }
}

@media (min-width: 1440px) {
  .intrusion {
    display: flex;
    width: 1312px;
    padding: 64px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 140px;
    gap: 64px;
    border-radius: 32px;

    &__title {
      text-align: center;
      font-size: 48px;
    }

    &__container {
      gap: 20px;
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    &__card {
      display: flex;
      height: 152px;
      padding: 16px 32px;
      border-radius: 32px;
    }

    &__number {
      font-size: 48px;
    }

    &__case {
      padding: 0px 32px;
      gap: 8px;
    }

    &__text {
      font-size: 16px;
      width: 242px;

      &_min {
        font-size: 16px;
      }

      &_beam {
        font-size: 16px;
        width: 317px;
      }
    }

    &__button {
      display: flex;
      padding: 12px 24px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-radius: 16px;
      background: var(--Button-color-button-default, $color-orange);
    }

    &__span {
      font-size: 16px;
    }

    &__arrow {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 5.333px 6.054px 5.334px 5.189px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Background-bg-element, $color-pale-orange);
    }
  }
}
