.description-ball {
  display: flex;
  padding: 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &__img {
    width: 328px;
    height: 446px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__portrait {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__topic {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    width: 295px;
    letter-spacing: 1.341px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    width: 312px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
    padding-left: 16px;
    border-left: 2px solid var(--Border-color-border-default, #d8d8d8);
  }

  &__gear {
    display: grid;
    gap: 20px;
  }

  &__boot {
    display: flex;
    gap: 1px;
  }

  &__walk {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.44px;
  }

  &__word {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
    width: 328px;

    &_big {
      font-weight: 700;
    }
  }

  &__case {
    display: grid;
    gap: 2px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__rubric {
    color: var(--Text-color-text-special, $color-blue);
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__crisper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  &__encash {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  &__plan {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .description-ball {
    display: flex;
    padding: 80px 38px;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &__img {
      width: 460px;
      height: 625px;
    }

    &__portrait {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__text {
      width: 678px;
    }

    &__gear {
      display: grid;
      gap: 20px;
    }

    &__boot {
      display: flex;
      gap: 1px;
    }

    &__word {
      width: 692px;
    }

    &__case {
      display: grid;
      gap: 2px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .description-ball {
    display: flex;
    padding: 140px 64px 100px 64px;
    gap: 64px;
    flex-direction: row;

    &__imgs {
      max-width: 500px;
      min-width: 380px;
    }

    &__img {
      width: 100%;
      height: initial;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 700px;
      min-width: 640px;
    }

    &__portrait {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__topic {
      font-size: 48px;
      width: 563px;
    }

    &__text {
      font-size: 16px;
      width: initial;
      padding-right: 48px;
    }

    &__gear {
      display: grid;
      gap: 20px;
    }

    &__boot {
      display: flex;
      gap: 1px;
    }

    &__word {
      font-size: 16px;
      width: initial;
    }

    &__case {
      display: grid;
      gap: 2px;
      padding-right: 48px;
    }

    &__rubric {
      font-size: 48px;
    }

    &__crisper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
    }

    &__encash {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }

    &__plan {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .description-ball {
    display: flex;
    padding: 140px 64px 100px 64px;
    gap: 64px;
    flex-direction: row;
    justify-content: center;

    &__img {
      width: 500px;
      height: 680px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__portrait {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__topic {
      font-size: 48px;
      width: 563px;
    }

    &__text {
      font-size: 16px;
      width: 716px;
    }

    &__gear {
      display: grid;
      gap: 20px;
    }

    &__boot {
      display: flex;
      gap: 1px;
      align-items: center;
    }

    &__word {
      font-size: 16px;
      width: 748px;
    }

    &__case {
      display: grid;
      gap: 2px;
    }

    &__rubric {
      font-size: 48px;
    }

    &__crisper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    &__encash {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }

    &__plan {
      font-size: 16px;
    }
  }
}
