.benefit {
  gap: 24px;
  margin: 60px 0;
  width: calc(100% - 16px);
  padding: 0 0 0 16px;
  overflow: hidden;

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
    margin-bottom: 24px;
  }
}

.slider {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &__body {
    display: flex;
    scroll-snap-type: x mandatory;
  }

  &__card {
    display: flex;
    min-width: 270px;
    height: 104px;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    box-sizing: border-box;
    border: 2px solid var(--Border-color-border-default, #d8d8d8);
    scroll-snap-align: start;
  }

  &__img {
    width: 44px;
    height: 44px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 80px;
    padding-left: 0;

    &__title {
      padding-left: 38px;
    }

    .slider {
      gap: 8px;
      margin-left: 38px;

      &__card {
        border-radius: 33px;
        height: 78px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
    margin-bottom: 140px;
    margin-top: initial;
    padding-left: 0;
    overflow: hidden;

    &__title {
      font-size: 48px;
      padding-left: 64px;
    }
  }

  .slider {
    display: flex;
    flex-direction: row;
    gap: initial;
    padding: initial;
    margin-left: 64px;

    &__card {
      width: 400px;
      height: 104px;
      padding: 16px 32px;
      gap: 16px;
      border-radius: 32px;
    }

    &__img {
      width: 64px;
      height: 64px;
    }

    &__text {
      width: 215px;
      font-size: 16px;
      letter-spacing: 0.44px;
    }
  }
}

@media (min-width: 1440px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
    margin-bottom: 140px;
    margin-top: initial;
    padding-left: 0;

    &__title {
      font-size: 48px;
      width: 1312px;
      margin: 0 auto;
    }

    .slider {
      display: flex;
      flex-direction: row;
      gap: initial;
      padding: initial;
      margin-left: calc((100% - 1312px) / 2);

      &__card {
        width: 400px;
        height: 104px;
        padding: 16px 32px;
        gap: 16px;
        border-radius: 32px;
      }

      &__img {
        width: 64px;
        height: 64px;
      }

      &__text {
        width: 215px;
        font-size: 16px;
        letter-spacing: 0.44px;
      }
    }
  }
}
