.home-page {
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .home-page {
    padding-left: 38px;
    padding-right: 38px;
  }
}
@media (min-width: 1200px) {
  .home-page {
    padding-left: 64px;
    padding-right: 64px;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  padding: 0;
}

button {
  border-width: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

h3 {
  margin: 0;
  padding: 0;
}

h4 {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #3cb4f6, #3cb4f6 41%, white 40%);
}
.header__container {
  display: grid;
  gap: 60px;
}
.header__wrap {
  display: flex;
  gap: 16px;
  padding-top: 57px;
  padding-left: 16px;
  padding-right: 16px;
}
.header__img {
  width: 62px;
  height: 68px;
}
.header__case {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header__imgs {
  width: 250px;
  padding-top: 16.424px;
}
.header__text {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.097px;
}
.header__depiction {
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header {
    border-radius: 0px 0px 0px 64px;
    background: linear-gradient(180deg, #3cb4f6, #3cb4f6 61%, white 61%);
  }
  .header__container {
    display: grid;
    gap: 60px;
  }
  .header__wrap {
    gap: 32px;
    padding-top: 57px;
    padding-left: 36px;
    padding-right: 90px;
  }
  .header__img {
    width: 120px;
    height: 133px;
  }
  .header__case {
    gap: 16px;
  }
  .header__imgs {
    width: 487px;
    padding-top: 32px;
  }
  .header__depiction {
    padding-left: 127px;
    padding-right: initial;
  }
}
@media (min-width: 1200px) {
  .header {
    display: none;
  }
}
.menu {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .menu {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .menu {
    display: flex;
    gap: 4%;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    justify-content: center;
  }
  .menu__list {
    display: flex;
    max-width: 979px;
    min-width: 700px;
    height: 56px;
    padding-right: 64px;
    padding-left: 64px;
    align-items: center;
    border-radius: 16px;
    background: var(--Background-bg-menu, rgba(0, 118, 172, 0.2));
    justify-content: space-between;
  }
  .menu__item {
    list-style-type: none;
  }
  .menu__link {
    color: var(--Text-color-text-white, #fff);
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.44px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .menu__link:hover {
    color: var(--Text-color-text-white, #3d3d40);
  }
}
@media (min-width: 1440px) {
  .menu {
    display: flex;
    width: 100%;
    align-items: center;
    width: 1312px;
    margin: 32px auto 0;
    justify-content: space-between;
  }
  .menu__list {
    display: flex;
    max-width: 979px;
    min-width: 700px;
    height: 56px;
    padding-right: 64px;
    padding-left: 64px;
    align-items: center;
    border-radius: 16px;
    background: var(--Background-bg-menu, rgba(0, 118, 172, 0.2));
    justify-content: space-between;
  }
  .menu__item {
    list-style-type: none;
  }
  .menu__link {
    color: var(--Text-color-text-white, #fff);
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.44px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .menu__link:hover {
    color: var(--Text-color-text-white, #3d3d40);
  }
  .menu__link.active {
    color: var(--Text-color-text-white, #3d3d40);
  }
}
.leaving {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
}
.leaving .side-button-2 {
  padding-left: 16px;
}
.leaving .side-panel-bg {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6274509804);
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  top: 0;
  left: -120vw;
  align-items: center;
}
.leaving .side-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1169px;
  color: #fff;
  padding: 32px 38px;
  background: var(--Border-color-border-accent, #3cb4f6);
  box-shadow: 0px 4px 12px 0px rgba(18, 24, 58, 0.16);
}
.leaving .side-button-1 {
  cursor: pointer;
}
.leaving #side-checkbox {
  display: none;
}
.leaving .side-case {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-top: 30px;
}
.leaving .side-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
}
.leaving .side-text {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}
.leaving .side-text.active {
  color: var(--Text-color-text-white, #3d3d40);
}
.leaving #side-checkbox:checked + .side-panel-bg {
  left: 0;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .leaving {
    display: flex;
    padding: 30px 38px;
  }
  .leaving .side-panel-bg {
    align-items: initial;
  }
  .leaving .side-panel {
    height: 438px;
    width: 360px;
    padding-bottom: 163px;
    flex-direction: column;
    border-radius: 0px 0px 64px 0px;
  }
  .leaving .side-button-1 {
    cursor: pointer;
  }
  .leaving #side-checkbox {
    display: none;
  }
  .leaving .side-case {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-top: 30px;
    height: 438px;
    width: 360px;
  }
  .leaving .side-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
  }
  .leaving .side-text {
    color: var(--Text-color-text-white, #ffffff);
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }
}
@media (min-width: 1200px) {
  .leaving {
    display: none;
  }
}
input[type=text]:focus,
input[type=password]:focus,
textarea[name=message]:focus {
  background-color: white;
  border: 2px solid #3cb4f6;
  outline: none;
}

.user-input-wrp .input {
  display: flex;
  padding: 4px 0px 4px 16px;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: groove 1px #e2dada;
}
.user-input-wrp.input_error .input {
  border: groove 2px #b3261e;
  color: #b3261e;
}
.user-input-wrp.input_error .input:focus {
  color: #b3261e;
}
.user-input-wrp.input_error .error-label {
  visibility: visible;
}
.user-input-wrp.input_error .logo-error {
  position: absolute;
  width: 24px;
  height: 24px;
  visibility: visible;
}

.user-input-wrp {
  position: relative;
  width: 100%;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  box-shadow: none !important;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: 15px;
  transition: 0.2s ease all;
  background: white;
  padding-left: 4px;
  padding-right: 4px;
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:disabled ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: -9px;
  left: 12px;
  font-size: 13px;
  opacity: 1;
  color: #3cb4f6;
}

.user-input-wrp input:disabled ~ .floating-label {
  color: #3d3d40;
}

.user-input-wrp textarea:focus ~ .floating-label,
.user-input-wrp textarea:disabled ~ .floating-label,
.user-input-wrp textarea:not(:focus):valid ~ .floating-label {
  top: -9px;
  left: 12px;
  font-size: 13px;
  opacity: 1;
  color: #3cb4f6;
}

.user-input-wrp textarea:disabled ~ .floating-label {
  color: #3d3d40;
}

.user-input-wrp .error-label {
  display: flex;
  visibility: hidden;
  pointer-events: none;
  margin-top: 4px;
  transition: 0.2s ease all;
  color: #b3261e;
  padding-left: 13px;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.user-input-wrp .logo-error {
  display: flex;
  visibility: hidden;
  pointer-events: none;
  margin-top: 4px;
  position: absolute;
  top: 8px;
  right: 13px;
}

.textarea {
  display: flex;
  padding: 16px 0px 4px 16px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: groove 1px #e2dada;
}

.crisper {
  display: flex;
}

.ref {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.cover {
  display: none;
}

.boot {
  display: none;
}

.button {
  display: flex;
  padding: 8px 18px;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: all 0.3s ease;
}
.button:hover {
  background: #fcbe56;
}

.span {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
}

.arrow {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}

button:hover {
  opacity: 1;
}

.cancelbtn {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: all 0.3s ease;
}
.cancelbtn:hover {
  background: #fcbe56;
}
.cancelbtn.button__error {
  background: #d8d8d8;
}
.cancelbtn.button__error .arrow {
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
}
.cancelbtn.button__success {
  background: #3cb4f6;
}
.cancelbtn.button__success .arrow {
  background: var(--Background-bg-light, #fff);
}
.cancelbtn.button__success .arrow svg path {
  fill: #3cb4f6;
}

.cancelbtn,
.signupbtn {
  float: left;
  width: 100%;
}

.send {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
}

.container {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.case {
  display: grid;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  justify-content: center;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5019607843);
}

.modal-content {
  position: relative;
  padding-top: 30px;
  padding-left: 16px;
  padding-bottom: 30px;
  padding-right: 16px;
  border-radius: 24px;
  width: 360px;
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
}
.modal-content .shut {
  right: 0px;
  height: 16px;
  display: flex;
  justify-content: end;
}
.modal-content .container {
  padding-top: 60px;
}

.close {
  cursor: pointer;
}

hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

.label {
  display: flex;
  padding: 11px;
  align-items: center;
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1199px) {
  input[type=text]:focus,
  input[type=password]:focus {
    background-color: white;
    outline: none;
  }
  .input {
    width: 100%;
    height: 48px;
  }
  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }
  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }
  .modal-content {
    margin: initial;
    padding-top: 30px;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 60px;
    border-radius: 24px;
    width: 80%;
  }
  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }
  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 47%;
    gap: 14px;
  }
  .crisper {
    display: none;
  }
  .clearfix {
    display: none;
  }
  .cover {
    display: flex;
  }
  .boot {
    display: flex;
  }
  .ref {
    font-size: 14px;
    width: 100%;
  }
  .span {
    color: var(--Text-color-text-white, #ffffff);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }
  .boot {
    width: 100%;
  }
  .boot .cancelbtn {
    width: 100%;
  }
  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }
  .span {
    font-size: 16px;
  }
  .send {
    font-size: 14px;
  }
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  .shut {
    padding: initial;
  }
  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }
  .checkbox {
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 1199px) and (max-width: 1439px) {
  input[type=text]:focus,
  input[type=password]:focus {
    background-color: white;
    outline: none;
  }
  .input {
    width: 100%;
    height: 48px;
  }
  .user-input-wrp input:focus ~ .floating-label,
  .user-input-wrp input:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: #3cb4f6;
  }
  .user-input-wrp textarea:focus ~ .floating-label,
  .user-input-wrp textarea:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: #3cb4f6;
  }
  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }
  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }
  .modal-content {
    margin: initial;
    padding-top: 64px;
    padding-left: 64px;
    padding-bottom: 140px;
    padding-right: 64px;
    border-radius: 24px;
    width: 80%;
  }
  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }
  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    width: 47%;
  }
  .crisper {
    display: none;
  }
  .clearfix {
    display: none;
  }
  .cover {
    display: flex;
  }
  .boot {
    display: flex;
  }
  .ref {
    font-size: 16px;
    width: 100%;
  }
  .span {
    color: var(--Text-color-text-white, #ffffff);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }
  .boot {
    width: 100%;
  }
  .boot .cancelbtn {
    width: 100%;
  }
  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }
  .span {
    font-size: 16px;
  }
  .send {
    font-size: 16px;
  }
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  .shut {
    padding: initial;
  }
  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }
  .checkbox {
    width: 18px;
    height: 18px;
  }
}
@media (min-width: 1440px) {
  input[type=text]:focus,
  input[type=password]:focus {
    background-color: white;
    outline: none;
  }
  .user-input-wrp input:focus ~ .floating-label,
  .user-input-wrp input:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: #3cb4f6;
  }
  .user-input-wrp textarea:focus ~ .floating-label,
  .user-input-wrp textarea:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: #3cb4f6;
  }
  .input {
    width: 100%;
    height: 48px;
  }
  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }
  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }
  .modal-content {
    margin: initial;
    padding-top: 64px;
    padding-left: 64px;
    padding-bottom: 140px;
    padding-right: 64px;
    border-radius: 24px;
    width: 1312px;
  }
  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }
  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    width: 47%;
  }
  .crisper {
    display: none;
  }
  .clearfix {
    display: none;
  }
  .cover {
    display: flex;
  }
  .boot {
    display: flex;
  }
  .ref {
    font-size: 16px;
    width: 100%;
  }
  .span {
    color: var(--Text-color-text-white, #ffffff);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }
  .boot {
    width: 100%;
  }
  .boot .cancelbtn {
    width: 100%;
  }
  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }
  .span {
    font-size: 16px;
  }
  .send {
    font-size: 16px;
  }
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  .shut {
    padding: initial;
  }
  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }
  .checkbox {
    width: 18px;
    height: 18px;
  }
}
.depiction-wrap {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 76px;
}
.depiction-wrap .depiction {
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 24px;
  background: var(--Background-bg-light, #fff);
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
}
.depiction-wrap .depiction__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.depiction-wrap .depiction__container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.depiction-wrap .depiction__case {
  display: grid;
  gap: 16px;
}
.depiction-wrap .depiction__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.depiction-wrap .depiction__img {
  width: 200px;
  height: 200px;
}
.depiction-wrap .depiction__button {
  display: flex;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: var(--Button-color-button-secondary, linear-gradient(270deg, #3cb4f6 0%, #1bcccc 100%));
  transition: all 0.3s ease;
}
.depiction-wrap .depiction__button:hover {
  background: #3cb4f6;
}
.depiction-wrap .depiction__span {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.depiction-wrap .depiction__arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Icon-color-icon-normal, #ffffff);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .depiction-wrap {
    padding-left: 127px;
    padding-right: initial;
  }
  .depiction-wrap .depiction {
    display: flex;
    padding: 38px;
    border-radius: 32px 0px 0px 32px;
  }
  .depiction-wrap .depiction__container {
    justify-content: center;
    gap: 32px;
    align-self: stretch;
    flex-direction: row;
  }
  .depiction-wrap .depiction__case {
    display: grid;
    gap: 16px;
  }
  .depiction-wrap .depiction__text {
    width: 100%;
  }
  .depiction-wrap .depiction__img {
    width: 150px;
    height: 150px;
  }
  .depiction-wrap .depiction__button {
    gap: 12px;
    width: 246px;
    background: var(--Button-color-button-secondary, linear-gradient(270deg, #3ab2f5 0%, #92ebe6 100%));
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .depiction-wrap {
    display: flex;
    justify-content: flex-end;
    background: initial;
    padding-left: initial;
    padding-right: initial;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 140px;
    z-index: 99;
  }
  .depiction-wrap .depiction {
    padding: 32px 64px;
    border-radius: 32px 0px 0px 32px;
  }
  .depiction-wrap .depiction__title {
    font-size: 48px;
  }
  .depiction-wrap .depiction__container {
    flex-direction: row;
  }
  .depiction-wrap .depiction__case {
    display: grid;
    gap: 16px;
  }
  .depiction-wrap .depiction__text {
    font-size: 16px;
    width: 683px;
  }
  .depiction-wrap .depiction__button {
    width: 264px;
    gap: 12px;
  }
  .depiction-wrap .depiction__span {
    font-size: 16px;
  }
}
@media (min-width: 1440px) and (max-width: 1719px) {
  .depiction-wrap {
    display: flex;
    justify-content: flex-end;
    background: initial;
    padding-left: initial;
    padding-right: initial;
    width: 100%;
    padding-top: 260px;
    padding-bottom: 140px;
    z-index: 99;
  }
  .depiction-wrap .depiction {
    padding: 32px 64px;
    border-radius: 32px 0px 0px 32px;
  }
  .depiction-wrap .depiction__title {
    font-size: 48px;
  }
  .depiction-wrap .depiction__container {
    flex-direction: row;
  }
  .depiction-wrap .depiction__case {
    display: grid;
    gap: 16px;
  }
  .depiction-wrap .depiction__text {
    font-size: 16px;
    width: 683px;
  }
  .depiction-wrap .depiction__button {
    width: 264px;
    gap: 12px;
  }
  .depiction-wrap .depiction__span {
    font-size: 16px;
  }
}
@media (min-width: 1720px) {
  .depiction-wrap {
    display: flex;
    justify-content: end;
    background: initial;
    padding-left: 43%;
    padding-right: initial;
    width: auto;
    padding-top: 260px;
    padding-bottom: 140px;
    z-index: 99;
  }
  .depiction-wrap .depiction {
    padding: 32px 20% 32px 64px;
    border-radius: 32px 0px 0px 32px;
    justify-content: stretch;
    width: 100%;
  }
  .depiction-wrap .depiction__title {
    font-size: 48px;
  }
  .depiction-wrap .depiction__container {
    flex-direction: row;
    justify-content: flex-start;
  }
  .depiction-wrap .depiction__case {
    display: grid;
    gap: 16px;
  }
  .depiction-wrap .depiction__text {
    font-size: 16px;
    width: 683px;
  }
  .depiction-wrap .depiction__button {
    width: 264px;
    gap: 12px;
  }
  .depiction-wrap .depiction__span {
    font-size: 16px;
  }
}
.benefit {
  gap: 24px;
  margin: 60px 0;
  width: calc(100% - 16px);
  padding: 0 0 0 16px;
  overflow: hidden;
}
.benefit__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
  margin-bottom: 24px;
}

.slider {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.slider::-webkit-scrollbar {
  display: none;
}
.slider__body {
  display: flex;
  scroll-snap-type: x mandatory;
}
.slider__card {
  display: flex;
  min-width: 270px;
  height: 104px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  box-sizing: border-box;
  border: 2px solid var(--Border-color-border-default, #d8d8d8);
  scroll-snap-align: start;
}
.slider__img {
  width: 44px;
  height: 44px;
}
.slider__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 80px;
    padding-left: 0;
  }
  .benefit__title {
    padding-left: 38px;
  }
  .benefit .slider {
    gap: 8px;
    margin-left: 38px;
  }
  .benefit .slider__card {
    border-radius: 33px;
    height: 78px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
    margin-bottom: 140px;
    margin-top: initial;
    padding-left: 0;
    overflow: hidden;
  }
  .benefit__title {
    font-size: 48px;
    padding-left: 64px;
  }
  .slider {
    display: flex;
    flex-direction: row;
    gap: initial;
    padding: initial;
    margin-left: 64px;
  }
  .slider__card {
    width: 400px;
    height: 104px;
    padding: 16px 32px;
    gap: 16px;
    border-radius: 32px;
  }
  .slider__img {
    width: 64px;
    height: 64px;
  }
  .slider__text {
    width: 215px;
    font-size: 16px;
    letter-spacing: 0.44px;
  }
}
@media (min-width: 1440px) {
  .benefit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
    margin-bottom: 140px;
    margin-top: initial;
    padding-left: 0;
  }
  .benefit__title {
    font-size: 48px;
    width: 1312px;
    margin: 0 auto;
  }
  .benefit .slider {
    display: flex;
    flex-direction: row;
    gap: initial;
    padding: initial;
    margin-left: calc((100% - 1312px) / 2);
  }
  .benefit .slider__card {
    width: 400px;
    height: 104px;
    padding: 16px 32px;
    gap: 16px;
    border-radius: 32px;
  }
  .benefit .slider__img {
    width: 64px;
    height: 64px;
  }
  .benefit .slider__text {
    width: 215px;
    font-size: 16px;
    letter-spacing: 0.44px;
  }
}
.integration {
  padding-bottom: 60px;
}
.integration__casket {
  display: flex;
  padding: 30px 16px 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  border-radius: 24px;
  background: var(--Background-bg-accent, #edf8ff);
}
.integration__examples {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.integration__caption {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.341px;
}
.integration__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  text-align: center;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.integration__text_big {
  font-weight: 700;
}
.integration__wrap {
  display: grid;
  gap: 36px;
}
.integration__crisper {
  display: grid;
  gap: 44px;
}
.integration__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 18px;
  align-self: stretch;
}
.integration__card {
  display: flex;
  padding: 8px 16px;
  height: 61px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  box-sizing: border-box;
  background: #1bcccc;
  text-decoration: none;
}
.integration__card_up {
  background: #123476;
}
.integration__card_liver {
  border: 1px solid var(--Border-color-border-accent, #3cb4f6);
  background: var(--Background-bg-light, #fff);
}
.integration__topic {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.integration__topic_liver {
  color: var(--Text-color-text-white, #3d3d40);
}
.integration__img {
  width: 24px;
  height: 39px;
}
.integration__img_up {
  width: 46px;
  height: 46px;
}
.integration__img_liver {
  width: 45px;
  height: 45px;
}
.integration__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.integration__slip {
  display: flex;
  width: 262px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid #3cb4f6;
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
}
.integration__slip_big {
  height: 108px;
}
.integration__done {
  width: 24px;
  height: 24px;
}
.integration__word {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.44px;
}
.integration__word_big {
  font-weight: 700;
}
.integration__button {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background: #edf8ff;
  border: 2px solid var(--Border-color-border-accent, #3cb4f6);
  transition: all 0.3s ease;
}
.integration__button:hover {
  background: #ffffff;
}
.integration__write {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
}
.integration__arrow {
  width: 32px;
  height: 32px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .integration {
    padding-bottom: 80px;
  }
  .integration__casket {
    display: flex;
    padding: 30px 16px 60px 16px;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    border-radius: 24px;
    background: var(--Background-bg-accent, #edf8ff);
  }
  .integration__examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .integration__caption {
    color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.341px;
  }
  .integration__text {
    color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }
  .integration__text_big {
    font-weight: 700;
  }
  .integration__crisper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    justify-content: center;
  }
  .integration__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 18px;
    align-self: stretch;
  }
  .integration__card {
    width: 244px;
  }
  .integration__topic {
    text-align: left;
  }
  .integration__slip {
    width: 209px;
  }
  .integration__slip_big {
    height: 126px;
  }
  .integration__done {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .integration {
    padding-bottom: 140px;
  }
  .integration__casket {
    padding: 64px;
    gap: 64px;
    border-radius: 32px;
  }
  .integration__caption {
    font-size: 48px;
  }
  .integration__text {
    font-size: 16px;
  }
  .integration__text_big {
    font-weight: 700;
  }
  .integration__wrap {
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
  .integration__crisper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .integration__container {
    gap: 26px;
    flex-grow: 1;
    flex-basis: 0;
  }
  .integration__topic {
    font-size: 16px;
    font-weight: 700;
  }
  .integration__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .integration__slip {
    width: 89%;
  }
  .integration__slip_big {
    height: 128px;
  }
  .integration__word {
    font-size: 16px;
  }
  .integration__write {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .integration {
    padding-bottom: 140px;
  }
  .integration__casket {
    padding: 64px;
    gap: 64px;
    width: 1312px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 32px;
  }
  .integration__caption {
    font-size: 48px;
  }
  .integration__text {
    font-size: 16px;
    width: 904px;
  }
  .integration__text_big {
    font-weight: 700;
  }
  .integration__wrap {
    display: grid;
    gap: 64px;
  }
  .integration__crisper {
    display: flex;
    width: 1184px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .integration__container {
    gap: 26px;
  }
  .integration__card {
    width: 368px;
  }
  .integration__case {
    padding: 20px 32px 0;
  }
  .integration__topic {
    font-size: 16px;
    font-weight: 700;
  }
  .integration__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .integration__slip {
    width: 334px;
  }
  .integration__slip_big {
    height: 128px;
  }
  .integration__word {
    font-size: 16px;
  }
  .integration__write {
    font-size: 16px;
  }
}
.statistics {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 60px;
}
.statistics__card {
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Background-bg-light, #fff);
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
}
.statistics__number {
  color: var(--Text-color-text-special, #3cb4f6);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.341px;
}
.statistics__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .statistics {
    padding: 0px 38px 80px 38px;
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 80px;
  }
  .statistics__card {
    width: 220px;
  }
  .statistics__number {
    color: var(--Text-color-text-special, #3cb4f6);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.341px;
  }
  .statistics__text {
    width: 188px;
    text-align: center;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .statistics {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding-bottom: 140px;
  }
  .statistics__card {
    width: 362px;
    height: 121px;
    padding: 16px 32px;
    border-radius: 32px;
  }
  .statistics__number {
    font-size: 44px;
    width: 340px;
  }
  .statistics__text {
    font-size: 14px;
  }
}
@media (min-width: 1440px) {
  .statistics {
    display: flex;
    flex-direction: row;
    gap: initial;
    width: 1312px;
    margin: 0 auto;
    justify-content: space-between;
    padding-bottom: 140px;
  }
  .statistics__card {
    width: 400px;
    height: 121px;
    padding: 16px 32px;
    border-radius: 32px;
  }
  .statistics__number {
    font-size: 48px;
    width: 340px;
  }
  .statistics__text {
    font-size: 16px;
  }
}
.projects {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 60px;
}
.projects__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.projects__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.projects__container {
  display: flex;
  padding: 16px;
  text-decoration: none;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Background-bg-accent, #edf8ff);
  transition: all 0.3s ease;
}
.projects__container:hover {
  background: #ffffff;
}
.projects__container:hover .projects__mount {
  background: #3cb4f6;
}
.projects__container:hover .projects__imgs path {
  fill: #ffffff;
}
.projects__case {
  display: flex;
  justify-content: space-between;
}
.projects__crisper {
  display: grid;
  gap: 8px;
}
.projects__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 127px;
}
.projects__depiction {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.projects__img {
  height: 135px;
  aspect-ratio: 1/1;
}
.projects__up {
  width: 100px;
  height: 135px;
}
.projects__liver {
  width: 100px;
  height: 135px;
}
.projects__we {
  width: 100px;
  height: 135px;
}
.projects__flame {
  width: 100px;
  height: 135px;
}
.projects__casket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 24px;
}
.projects__strip {
  width: 100%;
  border-top: 3px solid #f49800;
}
.projects__mount {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Icon-color-icon-normal, #ffffff);
  transition: all 0.3s ease;
}
.projects__imgs {
  width: 32px;
  height: 33px;
}
.projects__figure {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .projects {
    padding-bottom: 80px;
  }
  .projects__wrap {
    align-content: flex-start;
    gap: 32px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .projects__container {
    height: 259px;
    text-decoration: none;
    flex-direction: column;
    justify-content: space-between;
    gap: initial;
  }
  .projects__case {
    display: flex;
  }
  .projects__crisper {
    display: grid;
    gap: 8px;
    width: 196px;
  }
  .projects__text {
    color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 89px;
  }
  .projects__depiction {
    color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }
  .projects__casket {
    align-self: initial;
  }
  .projects__strip {
    width: 198px;
  }
  .projects__circle {
    padding: 12px 24px;
  }
  .projects__imgs {
    width: 32px;
    height: 33px;
  }
  .projects__figure {
    width: 330px;
    height: 259px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .projects {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-bottom: 140px;
  }
  .projects__title {
    font-size: 48px;
  }
  .projects__wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .projects__container {
    display: flex;
    width: 424px;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
  }
  .projects__case {
    display: flex;
  }
  .projects__crisper {
    display: grid;
    gap: 12px;
    width: 238px;
  }
  .projects__text {
    font-size: 32px;
    width: 277px;
  }
  .projects__depiction {
    font-size: 16px;
    width: 238px;
  }
  .projects__img {
    height: 194px;
    aspect-ratio: 1/1;
  }
  .projects__up {
    width: 145px;
    height: 194px;
  }
  .projects__liver {
    width: 145px;
    height: 194px;
  }
  .projects__we {
    width: 145px;
    height: 194px;
  }
  .projects__flame {
    width: 145px;
    height: 194px;
  }
  .projects__casket {
    width: 376px;
  }
  .projects__strip {
    width: 248px;
  }
  .projects__mount {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Icon-color-icon-normal, #ffffff);
  }
  .projects__imgs {
    width: 32px;
    height: 33px;
  }
  .projects__figure {
    width: 421px;
    height: 323px;
  }
}
@media (min-width: 1440px) {
  .projects {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 1312px;
    margin: 0 auto;
    padding-bottom: 140px;
  }
  .projects__title {
    font-size: 48px;
  }
  .projects__wrap {
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .projects__container {
    display: flex;
    width: 424px;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
  }
  .projects__case {
    display: flex;
  }
  .projects__crisper {
    display: grid;
    gap: 12px;
    width: 238px;
  }
  .projects__text {
    font-size: 32px;
    width: 277px;
  }
  .projects__depiction {
    font-size: 16px;
    width: 238px;
  }
  .projects__img {
    height: 194px;
    aspect-ratio: 1/1;
  }
  .projects__up {
    width: 145px;
    height: 194px;
  }
  .projects__liver {
    width: 145px;
    height: 194px;
  }
  .projects__we {
    width: 145px;
    height: 194px;
  }
  .projects__flame {
    width: 145px;
    height: 194px;
  }
  .projects__casket {
    width: 376px;
  }
  .projects__strip {
    width: 248px;
  }
  .projects__mount {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Icon-color-icon-normal, #ffffff);
  }
  .projects__imgs {
    width: 32px;
    height: 33px;
  }
  .projects__figure {
    width: 424px;
    height: 323px;
  }
}
.partners {
  display: grid;
  gap: 24px;
  padding-bottom: 60px;
}
.partners__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.partners__case {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
.partners__img {
  width: 154px;
  height: 46px;
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .partners {
    gap: 64px;
    padding-bottom: 140px;
  }
  .partners__text {
    font-size: 48px;
  }
  .partners__case {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
  }
  .partners__img {
    width: 220px;
    height: 64px;
  }
}
@media (min-width: 1440px) {
  .partners {
    gap: 64px;
    padding-bottom: 140px;
    width: 1312px;
    margin: 0 auto;
  }
  .partners__text {
    font-size: 48px;
  }
  .partners__case {
    display: flex;
    width: 1312px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
  .partners__img {
    width: 220px;
    height: 64px;
  }
}
.answer {
  display: flex;
  padding: 30px 16px 60px 16px;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  margin-bottom: 60px;
  background: var(--Background-bg-accent-gradient, linear-gradient(270deg, #56c2fe 0%, #99e2de 100%));
}
.answer__wrap {
  display: grid;
  gap: 16px;
}
.answer__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.answer__crisper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.answer .answer__container {
  width: 100%;
  transition: height 0.5s ease;
}
.answer .answer__container:nth-child(1) .answer__panel_show {
  height: 362px;
}
.answer .answer__container:nth-child(1) .answer__panel_show .answer__text {
  height: 321px;
}
.answer .answer__container:nth-child(2) .answer__panel_show {
  height: 295px;
}
.answer .answer__container:nth-child(2) .answer__panel_show .answer__text {
  height: 240px;
}
.answer .answer__container:nth-child(3) .answer__panel_show {
  height: 143px;
}
.answer .answer__container:nth-child(3) .answer__panel_show .answer__text {
  height: 94px;
}
.answer .answer__container:nth-child(4) .answer__panel_show {
  height: 179px;
}
.answer .answer__container:nth-child(4) .answer__panel_show .answer__text {
  height: 137px;
}
.answer .accordion {
  display: flex;
  padding: 16px 32px 0px 32px;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
}
.answer .accordion:after {
  content: "+";
  transition: transform 0.5s ease;
  font-size: 31px;
  color: #3d3d40;
}
.answer .active:after {
  transform: rotate(45deg);
}
.answer__span {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  width: 200px;
  letter-spacing: 0.44px;
}
.answer__panel {
  padding-left: 32px;
  padding-right: 32px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
  width: calc(100% - 64px);
  overflow: hidden;
  height: 16px;
  transition: height 0.5s ease;
}
.answer__panel .answer__text {
  height: 0;
  transition: height 0.5s ease;
  display: flex;
  overflow: hidden;
  padding-bottom: 0px;
  margin-top: 12px;
}
.answer__panel .answer__text_bonds {
  display: block;
}
.answer__panel_show {
  transform: translateY(0);
}
.answer__panel_show .answer__text {
  padding-bottom: 16px;
}
.answer__text {
  display: flex;
  flex-direction: column;
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  padding-bottom: 16px;
  transition: height 0.5s ease;
}
.answer__text p {
  transition: height 0.5s ease;
}
.answer__text_bonds {
  display: block;
}
.answer__ref {
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s ease;
}
.answer__ref:hover {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3cb4f6));
}
.answer__link {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  transition: all 0.5s ease;
}
.answer__link:hover {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3cb4f6));
}
.answer__button {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: height 0.3s ease;
  width: 194px;
}
.answer__button:hover {
  background: #fcbe56;
}
.answer__word {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.answer__arrow {
  display: flex;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .answer {
    width: 641px;
    margin-left: initial;
    margin-right: initial;
    border-radius: 0px 32px 32px 0px;
  }
  .answer__wrap {
    display: grid;
    gap: 16px;
  }
  .answer__text {
    width: 513px;
  }
  .answer .answer__container {
    width: 100%;
    transition: height 0.5s ease;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show {
    height: 160px;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show .answer__text {
    height: 130px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show {
    height: 150px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show .answer__text {
    height: 120px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer__panel {
    padding-left: 32px;
    padding-right: 32px;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    overflow: hidden;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .answer {
    padding: 32px 64px 64px 64px;
    width: 66%;
    margin-left: initial;
    margin-right: 64px;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;
  }
  .answer__title {
    font-size: 48px;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show {
    height: 160px;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show .answer__text {
    height: 130px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show {
    height: 150px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show .answer__text {
    height: 120px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .accordion:after {
    content: "+";
    font-size: 31px;
    color: #3d3d40;
  }
  .answer__span {
    width: 419px;
    font-size: 16px;
  }
  .answer__panel {
    padding-left: 32px;
    padding-right: 32px;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    height: 16px;
    overflow: hidden;
  }
  .answer__text {
    width: 750px;
    font-size: 16px;
  }
  .answer__link {
    font-size: 16px;
  }
}
@media (min-width: 1440px) and (max-width: 1719px) {
  .answer {
    padding: 32px 64px 64px 204px;
    width: 1043px;
    margin-left: initial;
    margin-right: 64px;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;
  }
  .answer__title {
    font-size: 48px;
  }
  .answer__wrap {
    display: grid;
    gap: 16px;
  }
  .answer .answer__container {
    width: 100%;
    transition: height 0.5s ease;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show {
    height: 160px;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show .answer__text {
    height: 130px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show {
    height: 150px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show .answer__text {
    height: 120px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .accordion:after {
    content: "+";
    font-size: 31px;
    color: #3d3d40;
  }
  .answer__span {
    width: 419px;
    font-size: 16px;
  }
  .answer__panel {
    padding-left: 32px;
    padding-right: 32px;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    overflow: hidden;
  }
  .answer__text {
    width: 750px;
    font-size: 16px;
  }
  .answer__link {
    font-size: 16px;
  }
}
@media (min-width: 1720px) {
  .answer {
    padding: 32px 64px 64px 20%;
    padding-left: 64px;
    width: auto;
    margin-left: initial;
    margin-right: 50%;
    gap: 32px;
    border-radius: 0px 32px 32px 0px;
    margin-bottom: 140px;
    justify-content: end;
    flex-direction: initial;
  }
  .answer__wrap {
    gap: 16px;
    flex-direction: column;
  }
  .answer__title {
    font-size: 48px;
  }
  .answer .answer__container {
    width: 100%;
    transition: height 0.5s ease;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show {
    height: 160px;
  }
  .answer .answer__container:nth-child(1) .answer__panel_show .answer__text {
    height: 130px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show {
    height: 150px;
  }
  .answer .answer__container:nth-child(2) .answer__panel_show .answer__text {
    height: 120px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(3) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show {
    height: 108px;
  }
  .answer .answer__container:nth-child(4) .answer__panel_show .answer__text {
    height: 70px;
  }
  .answer .accordion:after {
    content: "+";
    font-size: 31px;
    color: #3d3d40;
  }
  .answer__span {
    width: 419px;
    font-size: 16px;
  }
  .answer__panel {
    padding-left: 32px;
    padding-right: 32px;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    overflow: hidden;
  }
  .answer__text {
    width: 750px;
    font-size: 16px;
  }
  .answer__button {
    width: 198px;
  }
  .answer__link {
    font-size: 16px;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 30px 16px 60px 16px;
  background: var(--Background-bg-accent, #edf8ff);
}
.footer__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.footer__container {
  display: flex;
  width: 212px;
  align-items: center;
  gap: 10px;
}
.footer__img {
  width: 40px;
  height: 44px;
}
.footer__imgs {
  width: 161px;
  height: 27px;
}
.footer__cover {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footer__crisper {
  display: flex;
  align-items: center;
  gap: 16px;
}
.footer__network {
  width: 35px;
  height: 36px;
}
.footer__hollow {
  display: none;
}
.footer__void {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer__case {
  display: flex;
  align-items: center;
  gap: 16px;
}
.footer__wraper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__casket {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer__mail {
  position: relative;
  color: var(--Link-color-link-fill-dark, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}
.footer__mail:hover {
  color: #3cb4f6;
}
.footer__word {
  display: flex;
  flex-direction: column;
  color: var(--Link-color-link-fill-dark, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.footer__word_min {
  font-weight: 400;
}
.footer__text {
  color: var(--Link-color-link-fill-dark, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.footer__text:hover {
  color: #3cb4f6;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 60px 38px;
    background: var(--Background-bg-accent, #edf8ff);
  }
  .footer__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  .footer__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer__hollow {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer__cover {
    align-items: flex-end;
  }
  .footer__wraper {
    align-items: flex-end;
  }
  .footer__void {
    display: none;
  }
  .footer__img {
    width: 40px;
    height: 44px;
  }
  .footer__imgs {
    width: 161px;
    height: 27px;
  }
  .footer__casket {
    display: flex;
    gap: 16px;
    flex-direction: row;
  }
  .footer__case {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .footer__network {
    width: 36px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 64px 64px 84px 64px;
    background: var(--Background-bg-accent, #edf8ff);
  }
  .footer__wrap {
    display: flex;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .footer__container {
    display: flex;
    width: 212px;
    align-items: center;
    gap: 10px;
  }
  .footer__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer__hollow {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer__cover {
    align-items: flex-end;
  }
  .footer__wraper {
    align-items: flex-end;
  }
  .footer__void {
    display: none;
  }
  .footer__img {
    width: 59px;
    height: 65px;
  }
  .footer__imgs {
    width: 238px;
    height: 40px;
  }
  .footer__case {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .footer__casket {
    display: flex;
    gap: 16px;
    flex-direction: row;
  }
  .footer__mail {
    font-size: 16px;
  }
  .footer__network {
    width: 36px;
  }
  .footer__word {
    font-size: 16px;
  }
  .footer__text {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 64px 64px 84px 64px;
    background: var(--Background-bg-accent, #edf8ff);
  }
  .footer__wrap {
    display: flex;
    flex-direction: row;
    display: flex;
    width: 100%;
    width: 1312px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  .footer__container {
    display: flex;
    width: 212px;
    align-items: center;
    gap: 10px;
  }
  .footer__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer__hollow {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer__cover {
    align-items: flex-end;
  }
  .footer__wraper {
    align-items: flex-end;
  }
  .footer__void {
    display: none;
  }
  .footer__img {
    width: 59px;
    height: 65px;
  }
  .footer__imgs {
    width: 238px;
    height: 40px;
  }
  .footer__case {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .footer__casket {
    display: flex;
    gap: 16px;
    flex-direction: row;
  }
  .footer__mail {
    font-size: 16px;
  }
  .footer__network {
    width: 36px;
  }
  .footer__word {
    font-size: 16px;
  }
  .footer__text {
    font-size: 16px;
  }
}
.desktop {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .desktop {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .desktop {
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .desktop__case {
    position: absolute;
  }
  .desktop__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .desktop__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop__casket {
    padding-left: 64px;
    margin-top: 156px;
    z-index: 99;
    position: relative;
  }
  .desktop__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop__img {
    width: 120px;
    height: 133px;
  }
  .desktop__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop__imgs {
    width: 486px;
    height: 115px;
    padding-top: 32px;
  }
  .desktop__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1440px) and (max-width: 1719px) {
  .desktop {
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .desktop__case {
    position: absolute;
  }
  .desktop__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .desktop__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop__casket {
    z-index: 99;
    margin: 256px auto 0;
    width: 1312px;
  }
  .desktop__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop__img {
    width: 152px;
    height: 162px;
  }
  .desktop__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop__imgs {
    width: 486px;
    height: 115px;
    padding-top: 32px;
  }
  .desktop__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1720px) {
  .desktop {
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .desktop__case {
    position: absolute;
    width: 100%;
    height: 78%;
    background-color: #5bc5fe;
  }
  .desktop__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    border-radius: 0px 0px 0px 0px;
  }
  .desktop__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .desktop__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop__casket {
    z-index: 99;
    margin: 256px auto 0;
    width: 1312px;
  }
  .desktop__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop__img {
    width: 152px;
    height: 162px;
  }
  .desktop__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop__imgs {
    width: 486px;
    height: 115px;
    padding-top: 32px;
  }
  .desktop__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
.portfolio {
  border-radius: 0px 0px 24px 24px;
  background: var(--Background-bg-accent-gradient, linear-gradient(270deg, #56c2fe 0%, #99e2de 100%));
}

@media (min-width: 768px) and (max-width: 1199px) {
  .portfolio {
    border-radius: 0px 0px 0px 64px;
  }
}
@media (min-width: 1200px) {
  .portfolio {
    display: flex;
    padding: 32px 64px 84px 64px;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    border-radius: 0px 0px 0px 64px;
  }
}
.portfolio-header {
  display: flex;
  padding: 60px 16px 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.portfolio-header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.portfolio-header__button {
  display: flex;
  padding: 8px 16px;
  border-radius: 16px;
  background: var(--Background-bg-primary, #f49800);
}
.portfolio-header__title {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.portfolio-header__text {
  color: var(--Text-color-text-white, #ffffff);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.097px;
}
.portfolio-header__card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
}
.portfolio-header__exile {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  transition: all 0.5s ease;
}
.portfolio-header__exile:hover {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3cb4f6));
}
.portfolio-header__img {
  width: 328px;
  height: 290px;
  border-radius: 24px;
}
.portfolio-header__img_active {
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .portfolio-header {
    padding: 30px 38px 80px 38px;
    align-items: flex-end;
    gap: 24px;
    flex-direction: row;
  }
  .portfolio-header_wrap {
    justify-content: space-around;
    align-self: stretch;
    margin: initial;
    flex-direction: row;
  }
  .portfolio-header__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .portfolio-header__text {
    text-align: left;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .portfolio-header {
    display: flex;
    padding: initial;
    flex-direction: row;
    gap: 100px;
  }
  .portfolio-header__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .portfolio-header_wrap {
    justify-content: space-around;
    align-self: stretch;
    padding: initial;
    margin: initial;
    flex-direction: row;
    gap: 100px;
  }
  .portfolio-header__button {
    width: initial;
  }
  .portfolio-header__title {
    font-size: 41px;
  }
  .portfolio-header__text {
    font-size: 32px;
    width: initial;
    text-align: left;
  }
  .portfolio-header__card {
    padding: 16px 32px;
    width: initial;
  }
  .portfolio-header__exile {
    font-size: 16px;
  }
  .portfolio-header__img {
    width: 500px;
    height: 440px;
  }
  .portfolio-header__img_active {
    width: 240px;
    height: 234px;
  }
}
@media (min-width: 1440px) {
  .portfolio-header {
    display: flex;
    padding: initial;
    margin: 0 auto;
    box-sizing: border-box;
    flex-direction: row;
    gap: 100px;
  }
  .portfolio-header__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  .portfolio-header_wrap {
    justify-content: space-around;
    align-self: stretch;
    padding: initial;
    margin: initial;
    flex-direction: row;
    gap: 100px;
  }
  .portfolio-header__button {
    display: inline-block;
  }
  .portfolio-header__title {
    font-size: 46px;
  }
  .portfolio-header__text {
    font-size: 40px;
    width: 712px;
    text-align: left;
  }
  .portfolio-header__card {
    padding: 16px 32px;
    width: 464px;
  }
  .portfolio-header__exile {
    font-size: 16px;
  }
  .portfolio-header__img {
    width: 500px;
    height: 440px;
  }
  .portfolio-header__img_active {
    width: 240px;
    height: 234px;
  }
}
.description-ball {
  display: flex;
  padding: 60px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.description-ball__img {
  width: 328px;
  height: 446px;
}
.description-ball__container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.description-ball__portrait {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.description-ball__topic {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  width: 295px;
  letter-spacing: 1.341px;
}
.description-ball__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  width: 312px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  padding-left: 16px;
  border-left: 2px solid var(--Border-color-border-default, #d8d8d8);
}
.description-ball__gear {
  display: grid;
  gap: 20px;
}
.description-ball__boot {
  display: flex;
  gap: 1px;
}
.description-ball__walk {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.44px;
}
.description-ball__word {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
  width: 328px;
}
.description-ball__word_big {
  font-weight: 700;
}
.description-ball__case {
  display: grid;
  gap: 2px;
}
.description-ball__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.description-ball__rubric {
  color: var(--Text-color-text-special, #3cb4f6);
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.description-ball__crisper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.description-ball__encash {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.description-ball__icon {
  width: 30px;
  height: 30px;
}
.description-ball__plan {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .description-ball {
    display: flex;
    padding: 80px 38px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .description-ball__img {
    width: 460px;
    height: 625px;
  }
  .description-ball__portrait {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .description-ball__text {
    width: 678px;
  }
  .description-ball__gear {
    display: grid;
    gap: 20px;
  }
  .description-ball__boot {
    display: flex;
    gap: 1px;
  }
  .description-ball__word {
    width: 692px;
  }
  .description-ball__case {
    display: grid;
    gap: 2px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .description-ball {
    display: flex;
    padding: 140px 64px 100px 64px;
    gap: 64px;
    flex-direction: row;
  }
  .description-ball__imgs {
    max-width: 500px;
    min-width: 380px;
  }
  .description-ball__img {
    width: 100%;
    height: initial;
  }
  .description-ball__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 700px;
    min-width: 640px;
  }
  .description-ball__portrait {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .description-ball__topic {
    font-size: 48px;
    width: 563px;
  }
  .description-ball__text {
    font-size: 16px;
    width: initial;
    padding-right: 48px;
  }
  .description-ball__gear {
    display: grid;
    gap: 20px;
  }
  .description-ball__boot {
    display: flex;
    gap: 1px;
  }
  .description-ball__word {
    font-size: 16px;
    width: initial;
  }
  .description-ball__case {
    display: grid;
    gap: 2px;
    padding-right: 48px;
  }
  .description-ball__rubric {
    font-size: 48px;
  }
  .description-ball__crisper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .description-ball__encash {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .description-ball__plan {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .description-ball {
    display: flex;
    padding: 140px 64px 100px 64px;
    gap: 64px;
    flex-direction: row;
    justify-content: center;
  }
  .description-ball__img {
    width: 500px;
    height: 680px;
  }
  .description-ball__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .description-ball__portrait {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .description-ball__topic {
    font-size: 48px;
    width: 563px;
  }
  .description-ball__text {
    font-size: 16px;
    width: 716px;
  }
  .description-ball__gear {
    display: grid;
    gap: 20px;
  }
  .description-ball__boot {
    display: flex;
    gap: 1px;
    align-items: center;
  }
  .description-ball__word {
    font-size: 16px;
    width: 748px;
  }
  .description-ball__case {
    display: grid;
    gap: 2px;
  }
  .description-ball__rubric {
    font-size: 48px;
  }
  .description-ball__crisper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .description-ball__encash {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .description-ball__plan {
    font-size: 16px;
  }
}
.header-api {
  display: flex;
  height: 0;
  padding-bottom: 454px;
  flex-direction: column;
  background: var(--Border-color-border-accent, #3cb4f6);
  border-radius: 0px 0px 24px 24px;
}
.header-api__container {
  display: grid;
  gap: 60px;
}
.header-api__wrap {
  display: flex;
  gap: 16px;
  padding-top: 57px;
  padding-left: 16px;
  padding-right: 16px;
}
.header-api__img {
  width: 62px;
  height: 68px;
}
.header-api__case {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header-api__imgs {
  width: 250px;
  padding-top: 16.424px;
}
.header-api__text {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.097px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header-api {
    padding-bottom: 460px;
    border-radius: 0px 0px 0px 64px;
  }
  .header-api__container {
    display: grid;
    gap: 60px;
  }
  .header-api__wrap {
    display: flex;
    gap: 32px;
    padding-top: 60px;
    padding-left: 38px;
    padding-right: 90px;
  }
  .header-api__img {
    width: 120.584px;
    height: 133px;
  }
  .header-api__case {
    gap: 16px;
  }
  .header-api__imgs {
    width: 487px;
    padding-top: 32px;
  }
}
@media (min-width: 1200px) {
  .header-api {
    display: none;
  }
}
.architecture {
  display: flex;
  width: 328px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 233px;
  padding: 16px 0px;
  margin-bottom: 60px;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: var(--Background-bg-accent, #edf8ff);
}
.architecture__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  text-align: center;
  font-family: Nunito Sans;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.335px;
}
.architecture__img {
  width: 328px;
  height: 196px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .architecture {
    width: 692px;
    height: 492px;
    padding: 33.785px 0px;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 32px;
  }
  .architecture__text {
    font-size: 27px;
    letter-spacing: 0.708px;
  }
  .architecture__img {
    width: 692px;
    height: 414px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .architecture {
    display: flex;
    width: 1117px;
    height: 924px;
    padding: 64px;
    gap: 64px;
    border-radius: 32px;
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .architecture__text {
    font-size: 48px;
    letter-spacing: 1.341px;
  }
  .architecture__img {
    width: 1117px;
    height: 667px;
  }
}
@media (min-width: 1440px) {
  .architecture {
    display: flex;
    width: 1312px;
    height: 924px;
    padding: 64px;
    gap: 64px;
    border-radius: 32px;
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .architecture__text {
    font-size: 48px;
    letter-spacing: 1.341px;
  }
  .architecture__img {
    width: 1117px;
    height: 667px;
  }
}
.methods-api {
  padding: 0px 16px 60px 16px;
}
.methods-api__title {
  display: flex;
  padding-bottom: 24px;
  justify-content: center;
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.methods-api__wrap {
  padding-bottom: 60px;
}
.methods-api__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.methods-api__topic {
  color: var(--Background-bg-primary, #f49800);
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.097px;
}
.methods-api__topic_hue {
  color: var(--Text-color-text-special, #3cb4f6);
}
.methods-api__case {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.methods-api__boot {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.methods-api__check {
  width: 64px;
  height: 64px;
}
.methods-api__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.methods-api__banner {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 2px solid var(--Border-color-border-primary, #f49800);
}
.methods-api__banner_hue {
  border: 2px solid var(--Border-color-border-primary, #3cb4f6);
}
.methods-api__divide {
  width: 100%;
  border-top: 3px solid #d8d8d8;
  margin-top: 60px;
  margin-bottom: 60px;
}
.methods-api__key {
  display: flex;
  width: 100%;
  cursor: pointer;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: all 0.3s ease;
}
.methods-api__key:hover {
  background: #fcbe56;
}
.methods-api__word {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.methods-api__arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}
.methods-api__img {
  width: 20.757px;
  height: 21.333px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .methods-api {
    padding: 0px 38px 80px 38px;
  }
  .methods-api__wrap {
    display: flex;
    padding-bottom: 80px;
  }
  .methods-api__boot {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  .methods-api__check {
    width: 64px;
    height: 64px;
  }
  .methods-api__divide {
    width: initial;
    border-top: initial;
    border-left: 3px solid #d8d8d8;
    margin-top: initial;
    margin-bottom: initial;
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .methods-api {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 64px 140px 64px;
  }
  .methods-api__title {
    font-size: 48px;
    padding-bottom: 64px;
  }
  .methods-api__wrap {
    display: flex;
    padding-bottom: 60px;
  }
  .methods-api__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .methods-api__topic {
    font-size: 40px;
  }
  .methods-api__case {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .methods-api__boot {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  .methods-api__text {
    font-size: 16px;
  }
  .methods-api__banner {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 2px solid var(--Border-color-border-primary, #f49800);
  }
  .methods-api__banner_hue {
    border: 2px solid var(--Border-color-border-primary, #3cb4f6);
  }
  .methods-api__divide {
    width: initial;
    height: 1320px;
    border-top: initial;
    border-left: 3px solid #d8d8d8;
    margin-top: initial;
    margin-bottom: initial;
    margin-left: 76px;
    margin-right: 76px;
  }
  .methods-api__key {
    width: 400px;
    height: 64px;
  }
  .methods-api__word {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .methods-api {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 64px 140px 64px;
  }
  .methods-api__title {
    font-size: 48px;
    padding-bottom: 64px;
  }
  .methods-api__wrap {
    display: flex;
    padding-bottom: 60px;
  }
  .methods-api__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .methods-api__topic {
    font-size: 40px;
  }
  .methods-api__case {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .methods-api__boot {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  .methods-api__text {
    font-size: 16px;
    width: 473px;
  }
  .methods-api__banner {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 2px solid var(--Border-color-border-primary, #f49800);
  }
  .methods-api__banner_hue {
    border: 2px solid var(--Border-color-border-primary, #3cb4f6);
  }
  .methods-api__divide {
    width: initial;
    height: 1320px;
    border-top: initial;
    border-left: 3px solid #d8d8d8;
    margin-top: initial;
    margin-bottom: initial;
    margin-left: 76px;
    margin-right: 76px;
  }
  .methods-api__key {
    width: 400px;
    height: 64px;
  }
  .methods-api__word {
    font-size: 16px;
  }
}
.desktop-api {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .desktop-api {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .desktop-api {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-api__case {
    position: absolute;
  }
  .desktop-api__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-api__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-api__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-api__casket {
    margin-left: initial;
    margin-top: 100px;
    z-index: 99;
  }
  .desktop-api__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-api__img {
    width: 120px;
    height: 133px;
  }
  .desktop-api__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-api__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-api__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1440px) and (max-width: 1719px) {
  .desktop-api {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-api__case {
    position: absolute;
  }
  .desktop-api__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-api__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-api__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-api__casket {
    margin-left: initial;
    margin-top: 100px;
    z-index: 99;
  }
  .desktop-api__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-api__img {
    width: 120px;
    height: 133px;
  }
  .desktop-api__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-api__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-api__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1720px) {
  .desktop-api {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
  }
  .desktop-api__case {
    position: absolute;
    width: 100%;
    height: 980px;
    background-color: #5bc5fe;
  }
  .desktop-api__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-api__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-api__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-api__casket {
    margin-left: initial;
    margin-top: 102px;
    z-index: initial;
  }
  .desktop-api__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-api__img {
    width: 120px;
    height: 133px;
  }
  .desktop-api__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-api__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-api__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
.header-crm {
  display: flex;
  height: 0;
  padding-bottom: 454px;
  flex-direction: column;
  background: var(--Border-color-border-accent, #3cb4f6);
  border-radius: 0px 0px 24px 24px;
}
.header-crm__container {
  display: grid;
  gap: 60px;
}
.header-crm__wrap {
  display: flex;
  gap: 16px;
  padding-top: 57px;
  padding-left: 16px;
  padding-right: 16px;
}
.header-crm__img {
  width: 62px;
  height: 68px;
}
.header-crm__case {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header-crm__imgs {
  width: 250px;
  padding-top: 16.424px;
}
.header-crm__text {
  color: var(--Text-color-text-white, #ffffff);
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.097px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header-crm {
    padding-bottom: 460px;
    border-radius: 0px 0px 0px 64px;
  }
  .header-crm__container {
    display: grid;
    gap: 60px;
  }
  .header-crm__wrap {
    display: flex;
    gap: 32px;
    padding-top: 60px;
    padding-left: 38px;
    padding-right: 90px;
  }
  .header-crm__img {
    width: 120.584px;
    height: 133px;
  }
  .header-crm__case {
    gap: 16px;
  }
  .header-crm__imgs {
    width: 487px;
    padding-top: 32px;
  }
}
@media (min-width: 1200px) {
  .header-crm {
    display: none;
  }
}
.benefit-crm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 60px;
  margin-top: 60px;
  width: 100%;
  overflow: hidden;
}
.benefit-crm__slider {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  gap: 16px;
  background: #fff;
}
.benefit-crm__card {
  display: flex;
  width: 270px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 2px solid var(--Border-color-border-default, #d8d8d8);
}
.benefit-crm__img {
  width: 44px;
  height: 44px;
}
.benefit-crm__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .benefit-crm__slider {
    gap: initial;
    margin-left: 38px;
  }
  .benefit-crm__card {
    border-radius: 24px;
  }
  .benefit-crm__text {
    letter-spacing: 0.44px;
    width: 186px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    margin-bottom: 140px;
    margin-top: 140px;
  }
  .benefit-crm__slider {
    display: flex;
    flex-direction: row;
    gap: initial;
    padding: initial;
    margin-left: 64px;
  }
  .benefit-crm__card {
    width: 400px;
    height: 104px;
    box-sizing: border-box;
    padding: 16px 32px;
    gap: 16px;
    border-radius: 32px;
  }
  .benefit-crm__img {
    width: 64px;
    height: 64px;
  }
  .benefit-crm__text {
    width: 215px;
    font-size: 16px;
    letter-spacing: 0.44px;
  }
}
@media (min-width: 1440px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    margin-bottom: 140px;
    margin-top: 140px;
  }
  .benefit-crm__slider {
    display: flex;
    flex-direction: row;
    gap: initial;
    padding: initial;
    margin-left: 64px;
    margin-left: calc((100% - 1312px) / 2);
  }
  .benefit-crm__card {
    width: 400px;
    height: 104px;
    box-sizing: border-box;
    padding: 16px 32px;
    gap: 16px;
    border-radius: 32px;
  }
  .benefit-crm__img {
    width: 64px;
    height: 64px;
  }
  .benefit-crm__text {
    width: 215px;
    font-size: 16px;
    letter-spacing: 0.44px;
  }
}
.intrusion {
  display: flex;
  padding: 30px 16px;
  margin-bottom: 60px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Background-bg-accent, #edf8ff);
}
.intrusion__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.intrusion__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.intrusion__wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.intrusion__card {
  display: flex;
  padding: 32px 16px;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  border-radius: 24px;
  border: 2px solid var(--Border-color-border-accent, #3cb4f6);
  background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
}
.intrusion__number {
  color: var(--Text-color-text-special, #3cb4f6);
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.intrusion__case {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.intrusion__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.intrusion__text_min {
  font-size: 14px;
  font-weight: 400;
}
.intrusion__text_beam {
  font-size: 14px;
  font-weight: 400;
}
.intrusion__button {
  display: flex;
  padding: 12px 24px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: all 0.3s ease;
}
.intrusion__button:hover {
  background: #fcbe56;
}
.intrusion__span {
  color: var(--Text-color-text-white, #ffffff);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.intrusion__arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .intrusion {
    margin-bottom: 80px;
  }
  .intrusion__title {
    text-align: center;
    width: 642px;
  }
  .intrusion__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .intrusion__wrap {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .intrusion__card {
    padding: 16px;
  }
  .intrusion__number {
    color: var(--Text-color-text-special, #3cb4f6);
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }
  .intrusion__case {
    padding: 0px 16px;
  }
  .intrusion__text {
    width: 135px;
  }
  .intrusion__text_beam {
    width: 182px;
  }
  .intrusion__button {
    width: 182px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .intrusion {
    display: flex;
    padding: 64px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 140px;
    gap: 64px;
    border-radius: 32px;
  }
  .intrusion__title {
    text-align: center;
    font-size: 48px;
  }
  .intrusion__container {
    gap: 20px;
  }
  .intrusion__wrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .intrusion__card {
    display: flex;
    height: 152px;
    width: 300px;
    padding: 16px 32px;
    border-radius: 32px;
  }
  .intrusion__number {
    font-size: 44px;
  }
  .intrusion__case {
    padding: 0px 32px;
    gap: 8px;
  }
  .intrusion__text {
    font-size: 13px;
  }
  .intrusion__text_min {
    font-size: 13px;
  }
  .intrusion__text_beam {
    font-size: 16px;
    width: 237px;
  }
  .intrusion__button {
    display: flex;
    padding: 12px 24px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--Button-color-button-default, #f49800);
  }
  .intrusion__span {
    font-size: 16px;
  }
  .intrusion__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Background-bg-element, #cb7f00);
  }
}
@media (min-width: 1440px) {
  .intrusion {
    display: flex;
    width: 1312px;
    padding: 64px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 140px;
    gap: 64px;
    border-radius: 32px;
  }
  .intrusion__title {
    text-align: center;
    font-size: 48px;
  }
  .intrusion__container {
    gap: 20px;
  }
  .intrusion__wrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .intrusion__card {
    display: flex;
    height: 152px;
    padding: 16px 32px;
    border-radius: 32px;
  }
  .intrusion__number {
    font-size: 48px;
  }
  .intrusion__case {
    padding: 0px 32px;
    gap: 8px;
  }
  .intrusion__text {
    font-size: 16px;
    width: 242px;
  }
  .intrusion__text_min {
    font-size: 16px;
  }
  .intrusion__text_beam {
    font-size: 16px;
    width: 317px;
  }
  .intrusion__button {
    display: flex;
    padding: 12px 24px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--Button-color-button-default, #f49800);
  }
  .intrusion__span {
    font-size: 16px;
  }
  .intrusion__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Background-bg-element, #cb7f00);
  }
}
.machine {
  padding-bottom: 60px;
}
.machine__case {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 60px;
}
.machine__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.machine__wraper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.machine__wrap {
  display: flex;
  flex-direction: column;
}
.machine__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}
.machine__line {
  display: flex;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--Border-color-border-default, #d8d8d8);
}
.machine__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.machine__text_big {
  font-weight: 700;
}
.machine__img {
  width: 328px;
  height: 139px;
  border-radius: 3.466px;
}
.machine__imgs {
  width: 328px;
  height: 164px;
}
.machine__button {
  display: flex;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
  transition: all 0.3s ease;
}
.machine__button:hover {
  background: #fcbe56;
}
.machine__span {
  color: var(--Text-color-text-white, #ffffff);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.machine__arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
  }
  .machine__case {
    padding-bottom: 80px;
    align-items: center;
  }
  .machine__title {
    color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
    font-family: "Nunito Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }
  .machine__wraper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
  }
  .machine__wrap {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .machine__container {
    flex-direction: row;
  }
  .machine__line {
    display: none;
  }
  .machine__text {
    width: 334px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 140px;
  }
  .machine__case {
    display: flex;
    align-items: center;
    gap: 64px;
    padding-bottom: 140px;
  }
  .machine__title {
    font-size: 48px;
  }
  .machine__wraper {
    gap: 140px;
  }
  .machine__wrap {
    display: flex;
    flex-direction: column;
  }
  .machine__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    gap: initial;
  }
  .machine__line {
    display: none;
  }
  .machine__text {
    font-size: 16px;
  }
  .machine__img {
    width: 757px;
    height: 321px;
  }
  .machine__imgs {
    width: 757px;
    height: 378px;
  }
  .machine__button {
    width: 400px;
  }
  .machine__span {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .machine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 140px;
  }
  .machine__case {
    display: flex;
    align-items: center;
    gap: 64px;
    padding-bottom: 140px;
  }
  .machine__title {
    font-size: 48px;
  }
  .machine__wraper {
    gap: 140px;
  }
  .machine__wrap {
    display: flex;
    flex-direction: column;
  }
  .machine__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    gap: initial;
    width: 1312px;
  }
  .machine__line {
    display: none;
  }
  .machine__text {
    font-size: 16px;
    width: 424px;
  }
  .machine__img {
    width: 757px;
    height: 321px;
  }
  .machine__imgs {
    width: 757px;
    height: 378px;
  }
  .machine__button {
    width: 400px;
  }
  .machine__span {
    font-size: 16px;
  }
}
.desktop-crm {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .desktop-crm {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-crm__case {
    position: absolute;
  }
  .desktop-crm__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-crm__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-crm__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-crm__casket {
    margin-top: 100px;
    z-index: initial;
  }
  .desktop-crm__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-crm__img {
    width: 120px;
    height: 133px;
  }
  .desktop-crm__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-crm__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-crm__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1440px) and (max-width: 1719px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-crm__case {
    position: absolute;
  }
  .desktop-crm__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-crm__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-crm__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-crm__casket {
    padding-left: initial;
    margin-top: 100px;
    z-index: initial;
  }
  .desktop-crm__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-crm__img {
    width: 120px;
    height: 133px;
  }
  .desktop-crm__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-crm__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-crm__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1720px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
  }
  .desktop-crm__case {
    position: absolute;
    width: 100%;
    height: 980px;
    background-color: #5bc5fe;
  }
  .desktop-crm__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-crm__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-crm__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-crm__casket {
    padding-left: initial;
    margin-top: 100px;
    z-index: initial;
  }
  .desktop-crm__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-crm__img {
    width: 120px;
    height: 133px;
  }
  .desktop-crm__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-crm__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-crm__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
.error {
  display: flex;
  padding: 103px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Border-color-border-accent, #3cb4f6);
}
.error__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.error__case {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.error__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error__img {
  width: 62px;
  height: 68px;
}
.error__title {
  color: var(--Text-color-text-white, #ffffff);
  text-shadow: 0px 2.053px 11.291px rgba(0, 0, 0, 0.1);
  font-family: "Nunito Sans";
  font-size: 96px;
  font-weight: 700;
  letter-spacing: 1.097px;
}
.error__text {
  color: var(--Text-color-text-white, #ffffff);
  text-shadow: 0px 2.053px 11.291px rgba(0, 0, 0, 0.1);
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.097px;
}
.error__text_min {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.error__button {
  display: flex;
  width: 328px;
  height: 64px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 16px;
  background: var(--Button-color-button-default, #f49800);
}
.error__word {
  color: var(--Text-color-text-white, #ffffff);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.error__arrow {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5.333px 6.054px 5.334px 5.189px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--Background-bg-element, #cb7f00);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .error {
    padding: 206px 38px;
  }
  .error__wrap {
    display: flex;
    padding: 0px 38px;
    justify-content: center;
    gap: 60px;
  }
  .error__case {
    gap: 32px;
  }
  .error__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error__img {
    width: 120px;
    height: 132px;
  }
  .error__title {
    font-size: 128px;
  }
  .error__text_min {
    width: 328px;
  }
}
@media (min-width: 1200px) {
  .error {
    padding: 125px 374px;
  }
  .error__wrap {
    padding: 0px 38px;
    gap: 60px;
  }
  .error__case {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .error__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error__img {
    width: 120px;
    height: 132px;
  }
  .error__title {
    font-size: 200px;
  }
  .error__text {
    font-size: 40px;
  }
  .error__text_min {
    font-size: 16px;
    width: 468px;
  }
  .error__button {
    width: 400px;
  }
  .error__word {
    font-size: 16px;
  }
}
.provisions {
  padding: 60px 16px 60px 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.provisions__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.provisions__title {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 700;
}
.provisions__text {
  color: #000;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.provisions__text_big {
  font-weight: 700;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .provisions {
    padding: 80px 38px 80px 38px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .provisions {
    padding: 140px 64px 140px 64px;
  }
  .provisions__title {
    font-size: 32px;
  }
  .provisions__text {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .provisions {
    padding: 140px 64px 140px 64px;
    margin: 0 auto;
    width: 1312px;
    box-sizing: border-box;
  }
  .provisions__title {
    font-size: 32px;
  }
  .provisions__text {
    font-size: 16px;
  }
}
.product {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 60px;
  margin-top: 60px;
}
.product__title {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  text-align: center;
  font-family: Nunito Sans;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.335px;
}
.product__header {
  display: flex;
  justify-content: center;
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.341px;
}
.product__text {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.product__document {
  color: var(--Link-color-link-fill-dark, var(--Newspaper-Text-color-text-black, #3d3d40));
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.product__document:hover {
  color: var(--Text-color-text-black, var(--Newspaper-Text-color-text-black, #3cb4f6));
}

@media (min-width: 768px) and (max-width: 1199px) {
  .product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .product__title {
    font-size: 37px;
    text-align: center;
  }
  .product__text {
    text-align: center;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .product__title {
    font-size: 48px;
    text-align: center;
  }
  .product__header {
    font-size: 27px;
  }
  .product__text {
    text-align: center;
    font-size: 16px;
  }
  .product__document {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .product__title {
    font-size: 48px;
    text-align: center;
  }
  .product__header {
    font-size: 27px;
  }
  .product__text {
    text-align: center;
    font-size: 16px;
    width: 56%;
  }
  .product__document {
    font-size: 16px;
  }
}
.desktop-product {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .desktop-product {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .desktop-product {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-product__case {
    position: absolute;
  }
  .desktop-product__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-product__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-product__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-product__casket {
    margin-left: 10%;
    margin-top: 184px;
    z-index: 99;
  }
  .desktop-product__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-product__img {
    width: 120px;
    height: 133px;
  }
  .desktop-product__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-product__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-product__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}
@media (min-width: 1440px) {
  .desktop-product {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;
  }
  .desktop-product__case {
    position: absolute;
  }
  .desktop-product__video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
  }
  .desktop-product__wrap {
    display: flex;
    width: 100%;
  }
  .desktop-product__container {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  .desktop-product__casket {
    margin-left: 10%;
    margin-top: 184px;
    z-index: 99;
  }
  .desktop-product__cover {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }
  .desktop-product__img {
    width: 120px;
    height: 133px;
  }
  .desktop-product__crisper {
    display: grid;
    gap: 16px;
  }
  .desktop-product__imgs {
    width: 486px;
    height: 115px;
  }
  .desktop-product__text {
    color: var(--Text-color-text-white, #fff);
    text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1.097px;
  }
}