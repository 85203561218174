.methods-api {
  padding: 0px 16px 60px 16px;

  &__title {
    display: flex;
    padding-bottom: 24px;
    justify-content: center;
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__wrap {
    padding-bottom: 60px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  &__topic {
    color: var(--Background-bg-primary, $color-orange);
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.097px;

    &_hue {
      color: var(--Text-color-text-special, $color-blue);
    }
  }

  &__case {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__boot {
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  &__check {
    width: 64px;
    height: 64px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__banner {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 2px solid var(--Border-color-border-primary, $color-orange);

    &_hue {
      border: 2px solid var(--Border-color-border-primary, $color-blue);
    }
  }

  &__divide {
    width: 100%;
    border-top: 3px solid #d8d8d8;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &__key {
    display: flex;
    width: 100%;
    cursor: pointer;
    height: 64px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    background: var(--Button-color-button-default, $color-orange);
    transition: all 0.3s ease;

    &:hover {
      background: #fcbe56;
    }
  }

  &__word {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Background-bg-element, $color-pale-orange);
  }

  &__img {
    width: 20.757px;
    height: 21.333px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .methods-api {
    padding: 0px 38px 80px 38px;

    &__wrap {
      display: flex;
      padding-bottom: 80px;
    }

    &__boot {
      display: flex;
      align-items: center;
      align-self: stretch;
    }

    &__check {
      width: 64px;
      height: 64px;
    }

    &__divide {
      width: initial;
      border-top: initial;
      border-left: 3px solid #d8d8d8;
      margin-top: initial;
      margin-bottom: initial;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .methods-api {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 64px 140px 64px;

    &__title {
      font-size: 48px;
      padding-bottom: 64px;
    }

    &__wrap {
      display: flex;
      padding-bottom: 60px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;
    }

    &__topic {
      font-size: 40px;
    }

    &__case {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    &__boot {
      display: flex;
      align-items: center;
      align-self: stretch;
    }

    &__text {
      font-size: 16px;
      // width: 473px;
    }

    &__banner {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 16px;
      border: 2px solid var(--Border-color-border-primary, $color-orange);

      &_hue {
        border: 2px solid var(--Border-color-border-primary, $color-blue);
      }
    }

    &__divide {
      width: initial;
      height: 1320px;
      border-top: initial;
      border-left: 3px solid #d8d8d8;
      margin-top: initial;
      margin-bottom: initial;
      margin-left: 76px;
      margin-right: 76px;
    }

    &__key {
      width: 400px;
      height: 64px;
    }

    &__word {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .methods-api {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 64px 140px 64px;

    &__title {
      font-size: 48px;
      padding-bottom: 64px;
    }

    &__wrap {
      display: flex;
      padding-bottom: 60px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;
    }

    &__topic {
      font-size: 40px;
    }

    &__case {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    &__boot {
      display: flex;
      align-items: center;
      align-self: stretch;
    }

    &__text {
      font-size: 16px;
      width: 473px;
    }

    &__banner {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 16px;
      border: 2px solid var(--Border-color-border-primary, $color-orange);

      &_hue {
        border: 2px solid var(--Border-color-border-primary, $color-blue);
      }
    }

    &__divide {
      width: initial;
      height: 1320px;
      border-top: initial;
      border-left: 3px solid #d8d8d8;
      margin-top: initial;
      margin-bottom: initial;
      margin-left: 76px;
      margin-right: 76px;
    }

    &__key {
      width: 400px;
      height: 64px;
    }

    &__word {
      font-size: 16px;
    }
  }
}
