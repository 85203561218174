.integration {
  padding-bottom: 60px;

  &__casket {
    display: flex;
    padding: 30px 16px 60px 16px;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    border-radius: 24px;
    background: var(--Background-bg-accent, $color-container);
  }

  &__examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  &__caption {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.341px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;

    &_big {
      font-weight: 700;
    }
  }

  &__wrap {
    display: grid;
    gap: 36px;
  }

  &__crisper {
    display: grid;
    gap: 44px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 18px;
    align-self: stretch;
  }

  &__card {
    display: flex;
    padding: 8px 16px;
    height: 61px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    box-sizing: border-box;
    background: $color-turquoise;
    text-decoration: none;

    &_up {
      background: $color-secondary;
    }

    &_liver {
      border: 1px solid var(--Border-color-border-accent, #3cb4f6);
      background: var(--Background-bg-light, #fff);
    }
  }

  &__topic {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;

    &_liver {
      color: var(--Text-color-text-white, $color-pale-grey);
    }
  }

  &__img {
    width: 24px;
    height: 39px;

    &_up {
      width: 46px;
      height: 46px;
    }

    &_liver {
      width: 45px;
      height: 45px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__slip {
    display: flex;
    width: 262px;
    padding: 16px;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    border: 2px solid $color-blue;
    background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));

    &_big {
      height: 108px;
    }
  }

  &__done {
    width: 24px;
    height: 24px;
  }

  &__word {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.44px;

    &_big {
      font-weight: 700;
    }
  }

  &__button {
    display: flex;
    height: 56px;
    padding: 12px 24px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: $color-container;
    border: 2px solid var(--Border-color-border-accent, $color-blue);
    transition: all 0.3s ease;

    &:hover {
      background: $color-white;
    }
  }

  &__write {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }

  &__arrow {
    width: 32px;
    height: 32px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .integration {
    padding-bottom: 80px;

    &__casket {
      display: flex;
      padding: 30px 16px 60px 16px;
      flex-direction: column;
      align-items: center;
      gap: 36px;
      border-radius: 24px;
      background: var(--Background-bg-accent, $color-container);
    }

    &__examples {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }

    &__caption {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-pale-grey)
      );
      font-family: Nunito Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.341px;
    }

    &__text {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-pale-grey)
      );
      text-align: center;
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.44px;

      &_big {
        font-weight: 700;
      }
    }

    &__crisper {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 18px;
      align-self: stretch;
    }

    &__card {
      width: 244px;
    }

    &__topic {
      text-align: left;
    }

    &__slip {
      width: 209px;

      &_big {
        height: 126px;
      }
    }

    &__done {
      width: 24px;
      height: 24px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .integration {
    padding-bottom: 140px;

    &__casket {
      padding: 64px;
      gap: 64px;
      border-radius: 32px;
    }

    &__caption {
      font-size: 48px;
    }

    &__text {
      font-size: 16px;
      // width: 904px;

      &_big {
        font-weight: 700;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }

    &__crisper {
      display: flex;
      // width: 1184px;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__container {
      gap: 26px;
      flex-grow: 1;
      flex-basis: 0;
    }

    &__topic {
      font-size: 16px;
      font-weight: 700;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__slip {
      width: 89%;

      &_big {
        height: 128px;
      }
    }

    &__word {
      font-size: 16px;
    }

    &__write {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .integration {
    padding-bottom: 140px;

    &__casket {
      padding: 64px;
      gap: 64px;
      width: 1312px;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 32px;
    }

    &__caption {
      font-size: 48px;
    }

    &__text {
      font-size: 16px;
      width: 904px;

      &_big {
        font-weight: 700;
      }
    }

    &__wrap {
      display: grid;
      gap: 64px;
    }

    &__crisper {
      display: flex;
      width: 1184px;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__container {
      gap: 26px;
    }

    &__card {
      width: 368px;
    }

    &__case {
      padding: 20px 32px 0;
    }

    &__topic {
      font-size: 16px;
      font-weight: 700;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__slip {
      width: 334px;

      &_big {
        height: 128px;
      }
    }

    &__word {
      font-size: 16px;
    }

    &__write {
      font-size: 16px;
    }
  }
}
