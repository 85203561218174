.header-api {
  display: flex;
  height: 0;
  padding-bottom: 454px;
  flex-direction: column;
  background: var(--Border-color-border-accent, #3cb4f6);
  border-radius: 0px 0px 24px 24px;

  &__container {
    display: grid;
    gap: 60px;
  }

  &__wrap {
    display: flex;
    gap: 16px;
    padding-top: 57px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__img {
    width: 62px;
    height: 68px;
  }

  &__case {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__imgs {
    width: 250px;
    padding-top: 16.424px;
  }

  &__text {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.097px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header-api {
    padding-bottom: 460px;
    border-radius: 0px 0px 0px 64px;

    &__container {
      display: grid;
      gap: 60px;
    }

    &__wrap {
      display: flex;
      gap: 32px;
      padding-top: 60px;
      padding-left: 38px;
      padding-right: 90px;
    }

    &__img {
      width: 120.584px;
      height: 133px;
    }

    &__case {
      gap: 16px;
    }

    &__imgs {
      width: 487px;
      padding-top: 32px;
    }
  }
}

@media (min-width: 1200px) {
  .header-api {
    display: none;
  }
}
