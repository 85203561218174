.home-page {
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .home-page {
    padding-left: 38px;
    padding-right: 38px;
  }
}

@media (min-width: 1200px) {
  .home-page {
    padding-left: 64px;
    padding-right: 64px;
  }
}
