.desktop-crm {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .desktop-crm {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;

    &__case {
      position: absolute;
    }

    &__video {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 100px;
    }

    &__wrap {
      display: flex;
      width: 100%;
    }

    &__container {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &__casket {
      margin-top: 100px;
      z-index: initial;
    }

    &__cover {
      display: flex;
      gap: 32px;
      align-items: flex-start;
    }

    &__img {
      width: 120px;
      height: 133px;
    }

    &__crisper {
      display: grid;
      gap: 16px;
    }

    &__imgs {
      width: 486px;
      height: 115px;
    }

    &__text {
      color: var(--Text-color-text-white, #fff);
      text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
      font-family: Nunito Sans;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 1.097px;
    }
  }
}

@media (min-width: 1440px) and (max-width: 1719px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    border-radius: 0px 0px 0px 64px;

    &__case {
      position: absolute;
    }

    &__video {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 100px;
    }

    &__wrap {
      display: flex;
      width: 100%;
    }

    &__container {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &__casket {
      padding-left: initial;
      margin-top: 100px;
      z-index: initial;
    }

    &__cover {
      display: flex;
      gap: 32px;
      align-items: flex-start;
    }

    &__img {
      width: 120px;
      height: 133px;
    }

    &__crisper {
      display: grid;
      gap: 16px;
    }

    &__imgs {
      width: 486px;
      height: 115px;
    }

    &__text {
      color: var(--Text-color-text-white, #fff);
      text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
      font-family: Nunito Sans;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 1.097px;
    }
  }
}

@media (min-width: 1720px) {
  .desktop-crm {
    display: flex;
    position: relative;
    height: 540px;
    overflow: hidden;
    //border-radius: 0px 0px 0px 64px;

    &__case {
      position: absolute;
      width: 100%;
      height: 980px;
      background-color: #5bc5fe;
    }

    &__video {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 100px;
    }

    &__wrap {
      display: flex;
      width: 100%;
    }

    &__container {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &__casket {
      padding-left: initial;
      margin-top: 100px;
      z-index: initial;
    }

    &__cover {
      display: flex;
      gap: 32px;
      align-items: flex-start;
    }

    &__img {
      width: 120px;
      height: 133px;
    }

    &__crisper {
      display: grid;
      gap: 16px;
    }

    &__imgs {
      width: 486px;
      height: 115px;
    }

    &__text {
      color: var(--Text-color-text-white, #fff);
      text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
      font-family: Nunito Sans;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 1.097px;
    }
  }
}
