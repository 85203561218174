.benefit-crm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 60px;
  margin-top: 60px;
  width: 100%;
  overflow: hidden;

  &__slider {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    gap: 16px;
    background: #fff;
  }

  &__card {
    display: flex;
    width: 270px;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 2px solid var(--Border-color-border-default, #d8d8d8);
  }

  &__img {
    width: 44px;
    height: 44px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-top: 80px;
    margin-bottom: 60px;

    &__slider {
      gap: initial;
      margin-left: 38px;
    }

    &__card {
      border-radius: 24px;
    }

    &__text {
      letter-spacing: 0.44px;
      width: 186px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    margin-bottom: 140px;
    margin-top: 140px;

    &__slider {
      display: flex;
      flex-direction: row;
      gap: initial;
      padding: initial;
      margin-left: 64px;
    }

    &__card {
      width: 400px;
      height: 104px;
      box-sizing: border-box;
      padding: 16px 32px;
      gap: 16px;
      border-radius: 32px;
    }

    &__img {
      width: 64px;
      height: 64px;
    }

    &__text {
      width: 215px;
      font-size: 16px;
      letter-spacing: 0.44px;
    }
  }
}

@media (min-width: 1440px) {
  .benefit-crm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    margin-bottom: 140px;
    margin-top: 140px;

    &__slider {
      display: flex;
      flex-direction: row;
      gap: initial;
      padding: initial;
      margin-left: 64px;
      margin-left: calc((100% - 1312px) / 2);
    }

    &__card {
      width: 400px;
      height: 104px;
      box-sizing: border-box;
      padding: 16px 32px;
      gap: 16px;
      border-radius: 32px;
    }

    &__img {
      width: 64px;
      height: 64px;
    }

    &__text {
      width: 215px;
      font-size: 16px;
      letter-spacing: 0.44px;
    }
  }
}
