html {
  scroll-behavior: smooth;
}

body {
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  padding: 0;
}

button {
  border-width: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

h3 {
  margin: 0;
  padding: 0;
}

h4 {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}
