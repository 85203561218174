.portfolio {
  border-radius: 0px 0px 24px 24px;
  background: var(
    --Background-bg-accent-gradient,
    linear-gradient(270deg, #56c2fe 0%, #99e2de 100%)
  );
}

@media (min-width: 768px) and (max-width: 1199px) {
  .portfolio {
    border-radius: 0px 0px 0px 64px;
  }
}

@media (min-width: 1200px) {
  .portfolio {
    display: flex;
    padding: 32px 64px 84px 64px;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    border-radius: 0px 0px 0px 64px;
  }
}
