.menu {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .menu {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .menu {
    display: flex;
    gap: 4%;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    justify-content: center;

    &__list {
      display: flex;
      max-width: 979px;
      min-width: 700px;
      height: 56px;
      padding-right: 64px;
      padding-left: 64px;
      align-items: center;
      border-radius: 16px;
      background: var(--Background-bg-menu, rgba(0, 118, 172, 0.2));
      justify-content: space-between;
    }

    &__item {
      list-style-type: none;
    }

    &__link {
      color: var(--Text-color-text-white, #fff);
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.44px;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        color: var(--Text-color-text-white, $color-pale-grey);
      }
    }
  }
}

@media (min-width: 1440px) {
  .menu {
    display: flex;
    width: 100%;
    align-items: center;
    width: 1312px;
    margin: 32px auto 0;
    justify-content: space-between;

    &__list {
      display: flex;
      max-width: 979px;
      min-width: 700px;
      height: 56px;
      padding-right: 64px;
      padding-left: 64px;
      align-items: center;
      border-radius: 16px;
      background: var(--Background-bg-menu, rgba(0, 118, 172, 0.2));
      justify-content: space-between;
    }

    &__item {
      list-style-type: none;
    }

    &__link {
      color: var(--Text-color-text-white, #fff);
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.44px;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        color: var(--Text-color-text-white, $color-pale-grey);
      }

      &.active {
        color: var(--Text-color-text-white, $color-pale-grey);
      }
    }
  }
}
