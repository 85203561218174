.error {
  display: flex;
  padding: 103px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--Border-color-border-accent, $color-blue);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &__case {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    width: 62px;
    height: 68px;
  }

  &__title {
    color: var(--Text-color-text-white, $color-white);
    text-shadow: 0px 2.053px 11.291px rgba(0, 0, 0, 0.1);
    font-family: "Nunito Sans";
    font-size: 96px;
    font-weight: 700;
    letter-spacing: 1.097px;
  }

  &__text {
    color: var(--Text-color-text-white, $color-white);
    text-shadow: 0px 2.053px 11.291px rgba(0, 0, 0, 0.1);
    font-family: "Nunito Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.097px;

    &_min {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.44px;
    }
  }

  &__button {
    display: flex;
    width: 328px;
    height: 64px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    border-radius: 16px;
    background: var(--Button-color-button-default, $color-orange);
  }

  &__word {
    color: var(--Text-color-text-white, $color-white);
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 5.333px 6.054px 5.334px 5.189px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Background-bg-element, $color-pale-orange);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .error {
    padding: 206px 38px;

    &__wrap {
      display: flex;
      padding: 0px 38px;
      justify-content: center;
      gap: 60px;
    }

    &__case {
      gap: 32px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      width: 120px;
      height: 132px;
    }

    &__title {
      font-size: 128px;
    }

    &__text {
      &_min {
        width: 328px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .error {
    padding: 125px 374px;

    &__wrap {
      padding: 0px 38px;
      gap: 60px;
    }

    &__case {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      width: 120px;
      height: 132px;
    }

    &__title {
      font-size: 200px;
    }

    &__text {
      font-size: 40px;

      &_min {
        font-size: 16px;
        width: 468px;
      }
    }

    &__button {
      width: 400px;
    }

    &__word {
      font-size: 16px;
    }
  }
}
