input[type="text"]:focus,
input[type="password"]:focus,
textarea[name="message"]:focus {
  background-color: white;
  border: 2px solid $color-blue;
  outline: none;
}

.user-input-wrp {
  .input {
    display: flex;
    padding: 4px 0px 4px 16px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    border: groove 1px #e2dada;
  }
  &.input_error {
    .input {
      border: groove 2px #b3261e;
      color: #b3261e;
    }
    .input:focus {
      color: #b3261e;
    }
    .error-label {
      visibility: visible;
    }

    .logo-error {
      position: absolute;
      width: 24px;
      height: 24px;
      visibility: visible;
    }
  }
}

.user-input-wrp {
  position: relative;
  width: 100%;
}
.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  box-shadow: none !important;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: 15px;
  transition: 0.2s ease all;
  background: white;
  padding-left: 4px;
  padding-right: 4px;

  color: var(
    --Text-color-text-black,
    var(--Newspaper-Text-color-text-black, #3d3d40)
  );
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:disabled ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: -9px;
  left: 12px;
  font-size: 13px;
  opacity: 1;
  color: $color-blue;
}
.user-input-wrp input:disabled ~ .floating-label {
  color: #3d3d40;
}

.user-input-wrp textarea:focus ~ .floating-label,
.user-input-wrp textarea:disabled ~ .floating-label,
.user-input-wrp textarea:not(:focus):valid ~ .floating-label {
  top: -9px;
  left: 12px;
  font-size: 13px;
  opacity: 1;
  color: $color-blue;
}

.user-input-wrp textarea:disabled ~ .floating-label {
  color: #3d3d40;
}

.user-input-wrp .error-label {
  display: flex;
  visibility: hidden;
  pointer-events: none;
  margin-top: 4px;
  transition: 0.2s ease all;
  color: #b3261e;
  padding-left: 13px;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.user-input-wrp .logo-error {
  display: flex;
  visibility: hidden;
  pointer-events: none;
  margin-top: 4px;
  position: absolute;
  top: 8px;
  right: 13px;
}

.textarea {
  display: flex;
  padding: 16px 0px 4px 16px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: groove 1px #e2dada;
}

.crisper {
  display: flex;
}

.ref {
  color: var(
    --Text-color-text-black,
    var(--Newspaper-Text-color-text-black, $color-pale-grey)
  );
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.44px;
}

.cover {
  display: none;
}

.boot {
  display: none;
}

.button {
  display: flex;
  padding: 8px 18px;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  border-radius: 16px;
  background: var(--Button-color-button-default, $color-orange);
  transition: all 0.3s ease;

  &:hover {
    background: #fcbe56;
  }
}

.span {
  color: var(--Text-color-text-white, $color-white);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
}

.arrow {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Background-bg-element, $color-pale-orange);
}

button:hover {
  opacity: 1;
}

.cancelbtn {
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 16px;
  background: var(--Button-color-button-default, $color-orange);
  transition: all 0.3s ease;

  &:hover {
    background: #fcbe56;
  }

  &.button__error {
    background: #d8d8d8;
    .arrow {
      background: var(--Background-bg-question, rgba(255, 255, 255, 0.5));
    }
  }

  &.button__success {
    background: $color-blue;
    .arrow {
      background: var(--Background-bg-light, #fff);
      svg {
        path {
          fill: $color-blue;
        }
      }
    }
  }
}

.cancelbtn,
.signupbtn {
  float: left;
  width: 100%;
}

.send {
  color: var(--Text-color-text-white, $color-white);
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.44px;
}

.container {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.case {
  display: grid;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  justify-content: center;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5019607843);
}

.modal-content {
  position: relative;
  padding-top: 30px;
  padding-left: 16px;
  padding-bottom: 30px;
  padding-right: 16px;
  border-radius: 24px;
  width: 360px;
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
  .shut {
    right: 0px;
    height: 16px;
    display: flex;
    justify-content: end;
  }
  .container {
    padding-top: 60px;
  }
}

.close {
  cursor: pointer;
}

hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

.label {
  display: flex;
  padding: 11px;
  align-items: center;
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1199px) {
  input[type="text"]:focus,
  input[type="password"]:focus {
    background-color: white;
    outline: none;
  }

  .input {
    width: 100%;
    height: 48px;
  }

  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }

  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }

  .modal-content {
    margin: initial;
    padding-top: 30px;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 60px;
    border-radius: 24px;
    width: 80%;
  }

  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }

  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 47%;
    gap: 14px;
  }

  .crisper {
    display: none;
  }

  .clearfix {
    display: none;
  }

  .cover {
    display: flex;
  }

  .boot {
    display: flex;
  }

  .ref {
    font-size: 14px;
    width: 100%;
  }

  .span {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }

  .boot {
    width: 100%;
    .cancelbtn {
      width: 100%;
    }
  }

  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }

  .span {
    font-size: 16px;
  }

  .send {
    font-size: 14px;
  }

  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }

  .shut {
    padding: initial;
  }

  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 1199px) and (max-width: 1439px) {
  input[type="text"]:focus,
  input[type="password"]:focus {
    background-color: white;
    outline: none;
  }

  .input {
    width: 100%;
    height: 48px;
  }

  .user-input-wrp input:focus ~ .floating-label,
  .user-input-wrp input:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: $color-blue;
  }

  .user-input-wrp textarea:focus ~ .floating-label,
  .user-input-wrp textarea:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: $color-blue;
  }

  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }

  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }

  .modal-content {
    margin: initial;
    padding-top: 64px;
    padding-left: 64px;
    padding-bottom: 140px;
    padding-right: 64px;
    border-radius: 24px;
    width: 80%;
  }

  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }

  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    width: 47%;
  }

  .crisper {
    display: none;
  }

  .clearfix {
    display: none;
  }

  .cover {
    display: flex;
  }

  .boot {
    display: flex;
  }

  .ref {
    font-size: 16px;
    width: 100%;
  }

  .span {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }

  .boot {
    width: 100%;
    .cancelbtn {
      width: 100%;
    }
  }

  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }

  .span {
    font-size: 16px;
  }

  .send {
    font-size: 16px;
  }

  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }

  .shut {
    padding: initial;
  }

  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 1440px) {
  input[type="text"]:focus,
  input[type="password"]:focus {
    background-color: white;
    outline: none;
  }

  .user-input-wrp input:focus ~ .floating-label,
  .user-input-wrp input:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: $color-blue;
  }

  .user-input-wrp textarea:focus ~ .floating-label,
  .user-input-wrp textarea:not(:focus):valid ~ .floating-label {
    top: -9px;
    left: 12px;
    font-size: 13px;
    opacity: 1;
    color: $color-blue;
  }

  .input {
    width: 100%;
    height: 48px;
  }

  .textarea {
    width: 100%;
    height: 126px;
    padding: 16px 0px 4px 16px;
  }

  .modal {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
  }

  .modal-content {
    margin: initial;
    padding-top: 64px;
    padding-left: 64px;
    padding-bottom: 140px;
    padding-right: 64px;
    border-radius: 24px;
    width: 1312px;
  }

  .container {
    display: grid;
    gap: 16px;
    padding: initial;
  }

  .case {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    width: 47%;
  }

  .crisper {
    display: none;
  }

  .clearfix {
    display: none;
  }

  .cover {
    display: flex;
  }

  .boot {
    display: flex;
  }

  .ref {
    font-size: 16px;
    width: 100%;
  }

  .span {
    color: var(--Text-color-text-white, $color-white);
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
  }

  .boot {
    width: 100%;
    .cancelbtn {
      width: 100%;
    }
  }

  .button {
    width: 205px;
    height: 56px;
    justify-content: center;
  }

  .span {
    font-size: 16px;
  }

  .send {
    font-size: 16px;
  }

  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }

  .shut {
    padding: initial;
  }

  .label {
    display: flex;
    padding: 11px;
    align-items: center;
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }
}
