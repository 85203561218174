.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 30px 16px 60px 16px;
  background: var(--Background-bg-accent, $color-container);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__container {
    display: flex;
    width: 212px;
    align-items: center;
    gap: 10px;
  }

  &__img {
    width: 40px;
    height: 44px;
  }

  &__imgs {
    width: 161px;
    height: 27px;
  }

  &__cover {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__crisper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__network {
    width: 35px;
    height: 36px;
  }

  &__hollow {
    display: none;
  }

  &__void {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__case {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__wraper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__casket {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__mail {
    position: relative;
    color: var(
      --Link-color-link-fill-dark,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: $color-blue;
    }
  }

  &__word {
    display: flex;
    flex-direction: column;
    color: var(
      --Link-color-link-fill-dark,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.44px;
    &_min {
      font-weight: 400;
    }
  }

  &__text {
    color: var(
      --Link-color-link-fill-dark,
      var(--Newspaper-Text-color-text-black, $color-pale-grey)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease;

    &:hover {
      color: $color-blue;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 60px 38px;
    background: var(--Background-bg-accent, #edf8ff);

    &__wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }

    &__title {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__hollow {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__cover {
      align-items: flex-end;
    }

    &__wraper {
      align-items: flex-end;
    }

    &__void {
      display: none;
    }

    &__img {
      width: 40px;
      height: 44px;
    }

    &__imgs {
      width: 161px;
      height: 27px;
    }

    &__casket {
      display: flex;
      gap: 16px;
      flex-direction: row;
    }

    &__case {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__network {
      width: 36px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 64px 64px 84px 64px;
    background: var(--Background-bg-accent, #edf8ff);

    &__wrap {
      display: flex;
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &__container {
      display: flex;
      width: 212px;
      align-items: center;
      gap: 10px;
    }

    &__title {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__hollow {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__cover {
      align-items: flex-end;
    }

    &__wraper {
      align-items: flex-end;
    }

    &__void {
      display: none;
    }

    &__img {
      width: 59px;
      height: 65px;
    }

    &__imgs {
      width: 238px;
      height: 40px;
    }

    &__case {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__casket {
      display: flex;
      gap: 16px;
      flex-direction: row;
    }

    &__mail {
      font-size: 16px;
    }

    &__network {
      width: 36px;
    }

    &__word {
      font-size: 16px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 64px 64px 84px 64px;
    background: var(--Background-bg-accent, #edf8ff);

    &__wrap {
      display: flex;
      flex-direction: row;
      display: flex;
      width: 100%;
      width: 1312px;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
    }

    &__container {
      display: flex;
      width: 212px;
      align-items: center;
      gap: 10px;
    }

    &__title {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__hollow {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__cover {
      align-items: flex-end;
    }

    &__wraper {
      align-items: flex-end;
    }

    &__void {
      display: none;
    }

    &__img {
      width: 59px;
      height: 65px;
    }

    &__imgs {
      width: 238px;
      height: 40px;
    }

    &__case {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__casket {
      display: flex;
      gap: 16px;
      flex-direction: row;
    }

    &__mail {
      font-size: 16px;
    }

    &__network {
      width: 36px;
    }

    &__word {
      font-size: 16px;
    }

    &__text {
      font-size: 16px;
    }
  }
}
