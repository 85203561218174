.product {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 60px;
  margin-top: 60px;

  &__title {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    text-align: center;
    font-family: Nunito Sans;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.335px;
  }

  &__header {
    display: flex;
    justify-content: center;
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.341px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }

  &__document {
    color: var(
      --Link-color-link-fill-dark,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease;

    &:hover {
      color: var(
        --Text-color-text-black,
        var(--Newspaper-Text-color-text-black, $color-blue)
      );
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;

    &__title {
      font-size: 37px;
      text-align: center;
    }

    &__text {
      text-align: center;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;

    &__title {
      font-size: 48px;
      text-align: center;
    }

    &__header {
      font-size: 27px;
    }

    &__text {
      text-align: center;
      font-size: 16px;
    }

    &__document {
      font-size: 16px;
    }
  }
}

@media (min-width: 1440px) {
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;
    margin-left: 108px;
    margin-right: 108px;
    margin-bottom: 60px;
    margin-top: 60px;

    &__title {
      font-size: 48px;
      text-align: center;
    }

    &__header {
      font-size: 27px;
    }

    &__text {
      text-align: center;
      font-size: 16px;
      width: 56%;
    }

    &__document {
      font-size: 16px;
    }
  }
}
