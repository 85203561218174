.statistics {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 60px;

  &__card {
    display: flex;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 24px;
    background: var(--Background-bg-light, #fff);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1);
  }

  &__number {
    color: var(--Text-color-text-special, #3cb4f6);
    text-align: center;
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.341px;
  }

  &__text {
    color: var(
      --Text-color-text-black,
      var(--Newspaper-Text-color-text-black, #3d3d40)
    );
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.44px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .statistics {
    padding: 0px 38px 80px 38px;
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 80px;

    &__card {
      width: 220px;
    }

    &__number {
      color: var(--Text-color-text-special, #3cb4f6);
      text-align: center;
      font-family: Nunito Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.341px;
    }

    &__text {
      width: 188px;
      text-align: center;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .statistics {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding-bottom: 140px;

    &__card {
      width: 362px;
      height: 121px;
      padding: 16px 32px;
      border-radius: 32px;
    }

    &__number {
      font-size: 44px;
      width: 340px;
    }

    &__text {
      font-size: 14px;
    }
  }
}

@media (min-width: 1440px) {
  .statistics {
    display: flex;
    flex-direction: row;
    gap: initial;
    width: 1312px;
    margin: 0 auto;
    justify-content: space-between;
    padding-bottom: 140px;

    &__card {
      width: 400px;
      height: 121px;
      padding: 16px 32px;
      border-radius: 32px;
    }

    &__number {
      font-size: 48px;
      width: 340px;
    }

    &__text {
      font-size: 16px;
    }
  }
}
